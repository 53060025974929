import styled from "@emotion/styled";

export const QuestionTooltipWrapper = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  & > button > svg {
    transition: all 0.3s ease;
    color: #8f95a1;
  }
  &:hover {
    & > button > svg {
      color: #4cbfff;
    }
  }
`;
