import styled from "@emotion/styled";

export const UserMenuWrapper = styled.div<{ mode: "dark" | "light" }>`
  box-sizing: border-box;
  .MuiButtonBase-root {
    font-weight: 600;
    text-transform: inherit;
    color: ${({ mode }) => (mode === "light" ? "#fff" : "#000")};
  }
`;
