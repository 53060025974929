import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { API_TAGS } from "../../helpers/constants";
import { customHistory } from "../../helpers/CustomerBrowserRouter";
import { ICase } from "../../types/Case";
import { caseApi } from "../case/api";

const initialState: {
  currentCase: ICase | null;
  caseLoading: boolean;
} = {
  currentCase: null,
  caseLoading: false,
};

const caseSlice = createSlice({
  name: API_TAGS.CASE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        caseApi.endpoints.postCaseCreate.matchFulfilled,
        (state, action) => {
          // customHistory.push(`/app/cases/${action.payload.id}`);
        }
      )

      .addMatcher(caseApi.endpoints.putCaseUpdate.matchPending, (state) => {
        state.caseLoading = true;
      })
      .addMatcher(caseApi.endpoints.putCaseUpdate.matchFulfilled, (state) => {
        state.caseLoading = false;
      })
      .addMatcher(caseApi.endpoints.putCaseUpdate.matchFulfilled, (state) => {
        state.caseLoading = false;
      })

      .addMatcher(caseApi.endpoints.getCase.matchPending, (state) => {
        state.caseLoading = true;
      })
      .addMatcher(caseApi.endpoints.getCase.matchFulfilled, (state, action) => {
        state.currentCase = action.payload;
        state.caseLoading = false;
      })
      .addMatcher(caseApi.endpoints.getCase.matchFulfilled, (state) => {
        state.caseLoading = false;
      });
  },
});

export const {} = caseSlice.actions;
export default caseSlice.reducer;
