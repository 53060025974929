import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import resourcesToBackend from "i18next-resources-to-backend";

function genRandonNumber(length: number) {
  const chars = "0123456789";
  const charLength = chars.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

const HASH = genRandonNumber(10);

const options = {
  backend: {
    backends: [
      LocalStorageBackend, // primary ressources (cache = window.localStorage)
      resourcesToBackend((language, namespace, callback) => {
        import(`/public/locales/${language}/${namespace}.json`)
          .then((resources) => {
            callback(null, resources);
          })
          .catch((error) => {
            callback(error, null);
          });
      }), // fallback resources (json)
    ],
    backendOptions: [
      {
        expirationTime: 24 * 60 * 60 * 1000 * 7, // 7 days
        defaultVersion: "v" + HASH, // generate a new version every build to refresh
      },
    ],
  },
  // disabled in production
  debug: process.env.NODE_ENV !== "production",
  // can have multiple namespaces, in case you want to divide a huge
  // translation into smaller pieces and load them on demand
  ns: ["page", "common", "form", "app", "questionnaire"],

  interpolation: {
    espaceValue: false,
    formatSeparator: ",",
  },
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options);

i18n.on("languageChanged", () => {
  document.documentElement.lang = i18n.language;
});

export default i18n;
