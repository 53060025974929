import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";

import { AppBodyLayoutWrapper } from "./styles";
const AppBodyLayout = () => {
  return (
    <AppBodyLayoutWrapper>
      <Container>
        <Outlet />
      </Container>
    </AppBodyLayoutWrapper>
  );
};

export default AppBodyLayout;
