import React from "react";
import { HiEnvelope } from "react-icons/hi2";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, Typography } from "@mui/material";

import AppCheckbox from "../../components/AppCheckbox";
import Input from "../../components/Input";
import PATH from "../../helpers/PATH";

import { SignInWrapper } from "./styles";
import { useSignInForm } from "./useSignInForm";

const SignIn = () => {
  const { formik, handleRememberMe, isRememberMe, t } = useSignInForm();
  return (
    <SignInWrapper>
      <form onSubmit={formik.handleSubmit}>
        <Grid spacing={2} container>
          <Grid sx={{ marginBottom: 2, marginLeft: 1 }} xs={12} item>
            <Typography variant="h3">
              {t("common:signIn", { context: "account" })}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.email && !!formik.errors.email}
              helperText={(formik.touched.email && formik.errors.email) || ""}
              icon={<HiEnvelope />}
              label={t("common:email", { suffix: "" }) || ""}
              name="email"
              tooltipText={
                t("form:validation", { context: "enterMailWithExample" }) || ""
              }
              type="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.password && !!formik.errors.password}
              helperText={
                (formik.touched.password && formik.errors.password) || ""
              }
              icon={<HiEnvelope />}
              label={t("form:validation", { context: "enterPassword" }) || ""}
              name="password"
              // tooltipText="Пароль должен содержать латинские буквы разного регистра, цифры, а так же специальные символы (@, #, $ и тд…)"
              tooltipText={
                t("form:validation", { context: "enterPassword" }) || ""
              }
              type="password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="space-between"
            md={11}
            xs={12}
            container
            item
          >
            <Grid xs="auto" item>
              <AppCheckbox
                checked={isRememberMe}
                label={t("form:rememberMe") || ""}
                onChange={handleRememberMe}
              />
            </Grid>
            <Grid xs="auto" item>
              <Link
                className="text text--blue text--bold"
                to={PATH.APP_FORGOT_PASSWORD}
              >
                {t("form:forgotPassword")}
              </Link>
            </Grid>
          </Grid>
          <Grid md={11} xs={12} item>
            <LoadingButton type="submit" variant="app-button">
              {t("common:signIn")}
            </LoadingButton>
          </Grid>
          <Grid md={11} xs={12} item>
            <Box sx={{ textAlign: "center" }}>
              <p className="text">
                <span>{t("form:notCreatedAccountYet")}</span> &nbsp;
                <Link
                  className="text text--bold text--primary"
                  to={PATH.APP_SIGNUP}
                >
                  {t("common:signUp")}
                </Link>
              </p>
            </Box>
          </Grid>
        </Grid>
      </form>
    </SignInWrapper>
  );
};

export default SignIn;
