import React from "react";
import { HiEnvelope } from "react-icons/hi2";
import { Link } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, Typography } from "@mui/material";

import headSkeletImg from "../../assets/images/head-skelet.png";
import CustomDateTimePicker from "../../components/CustomDateTimePicker";
import Input from "../../components/Input";
import PhoneInput from "../../components/PhoneInput";
import PATH from "../../helpers/PATH";
import Icon from "../../icons";

import { SignUpWrapper } from "./styles";
import { useSignUpForm } from "./useSignUpForm";

const SignUp = () => {
  const { formik, isAgree, handleIsAgree, t, Trans, isLoading, isSuccess } =
    useSignUpForm();

  return (
    <SignUpWrapper>
      {isSuccess ? (
        <Grid
          spacing={2}
          sx={{ maxWidth: "400px", maxHeight: "200px" }}
          container
        >
          <Grid sx={{ marginBottom: 2, marginLeft: 1 }} xs={12} item>
            <Typography variant="h3">{t("app:thanksResponse")}</Typography>
          </Grid>
          <Grid alignItems="flex-start" md={11} xs={12} item>
            <Box sx={{ textAlign: "center" }}>
              <p className="text">
                <span>{t("form:haveAnAccount")}</span> &nbsp;
                <Link
                  className="text text--bold text--primary"
                  to={PATH.APP_SIGNIN}
                >
                  {t("common:signIn", { context: "you" })}
                </Link>
              </p>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid spacing={2} container>
            <Grid sx={{ marginBottom: 2, marginLeft: 1 }} xs={12} item>
              <Typography variant="h3">{t("common:bookDemo")}</Typography>
            </Grid>
            <Grid xs={12} item>
              <Input
                error={formik.touched.email && !!formik.errors.email}
                helperText={(formik.touched.email && formik.errors.email) || ""}
                icon={<HiEnvelope />}
                label={t("common:email", { suffix: "" }) || ""}
                name="email"
                type="email"
                value={formik.values.email}
                variant="filled"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={12} item>
              <Input
                error={formik.touched.name && !!formik.errors.name}
                helperText={(formik.touched.name && formik.errors.name) || ""}
                icon={<Icon icon="user-contacts" />}
                label={t("common:name", { suffix: "" }) || ""}
                name="name"
                type="text"
                value={formik.values.name}
                variant="filled"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={12} item>
              <Input
                error={formik.touched.country && !!formik.errors.country}
                helperText={
                  (formik.touched.country && formik.errors.country) || ""
                }
                icon={<PublicIcon />}
                label={t("common:country", { suffix: "" }) || ""}
                name="country"
                type="text"
                value={formik.values.country}
                variant="filled"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={12} item>
              <CustomDateTimePicker
                label={t("app:bookingDate") || ""}
                value={formik.values.bookingDate}
                onChange={(value) => formik.setFieldValue("bookingDate", value)}
              />
              <p className="error-text">
                {formik.touched.bookingDate && formik.errors.bookingDate}
              </p>
            </Grid>
            <Grid xs={12} item>
              <PhoneInput
                containerStyle={{ maxWidth: "100%" }}
                inputStyle={{ fontSize: "16px" }}
                placeholder={t("common:phoneNum") || ""}
                value={formik.values.phoneNumber}
                onChange={(value) => formik.setFieldValue("phoneNumber", value)}
              />
            </Grid>
            <Grid md={11} xs={12} item>
              <LoadingButton
                loading={isLoading}
                type="submit"
                variant="app-button"
              >
                {t("common:bookDemo")}
              </LoadingButton>
            </Grid>
            <Grid alignItems="flex-start" md={11} xs={12} item>
              <Box sx={{ textAlign: "center" }}>
                <p className="text">
                  <span>{t("form:haveAnAccount")}</span> &nbsp;
                  <Link
                    className="text text--bold text--primary"
                    to={PATH.APP_SIGNIN}
                  >
                    {t("common:signIn", { context: "you" })}
                  </Link>
                </p>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
      <div className="info">
        <Trans context="block" i18nKey="page:opportunity">
          <p>
            Мы даем вам возможность бесплатно провести первый анализ с помощью
            AIdentis. <br />
            Полная функциональность. <br />
            Нет ограничения во времени.
          </p>
          <ul>
            <li>
              Расстановка цефалометрических точек с помощью нейросетевых
              алгоритмов
            </li>
            <li>Автоматический расчет всех измерений</li>
            <li>Калибровка для достижения наилучших результатов</li>
            <li>Возможность выбора автора</li>
            <li>Просмотр и загрузка отчетов</li>
          </ul>
        </Trans>
        <div
          className="info-img"
          style={{ backgroundImage: `url(${headSkeletImg})` }}
        ></div>
      </div>
    </SignUpWrapper>
  );
};

export default SignUp;
