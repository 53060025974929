import styled from "@emotion/styled";

export const AppHeaderWrapper = styled.header`
  box-sizing: border-box;
  padding: 24px 0;
  border-bottom: 1px solid #353f4f;
  background: #151c27;
  .logo {
    display: flex;
    align-items: center;
    height: 25px;
  }
  .header {
    height: 25px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    menu {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      list-style-type: none;
      li {
        margin-right: 22px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
  }
`;
