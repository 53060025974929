import styled from "@emotion/styled";

export const ForgotPasswordWrapper = styled.div<{ step: number }>`
  box-sizing: border-box;
  padding: 20px;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: ${({ step }) => (step === 3 ? "center" : "flex-start")};
  form {
    max-width: ${({ step }) => (step === 3 ? "" : "500px")};
  }
`;
