import React from "react";
import { GoEye, GoEyeClosed } from "react-icons/go";
import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { FilledInputProps } from "@mui/material/FilledInput";

import QuestionTooltip from "../QuestionTooltip";

import { InputWrapper, TInputSize, TipWrapper } from "./styles";

interface IProps extends Partial<FilledInputProps> {
  onChange?: FilledInputProps["onChange"];
  variant?: "filled";
  icon?: React.ReactNode;
  label?: string;
  helperText?: string;
  tooltipText?: string | React.ReactNode;
  inputSize?: TInputSize;
}

const Input: React.FC<IProps> = (props) => {
  const {
    label,
    type = "text",
    icon,
    error,
    helperText,
    tooltipText,
    inputSize = "lg",
    ...otherProps
  } = props;
  const [showPassword, setShowPassword] = React.useState(type !== "password");

  const handleClickShowPassword = () =>
    type === "password" && setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const renderEndAdornment = () => {
    if (type === "password") {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            edge="end"
            tabIndex={-1}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <GoEyeClosed /> : <GoEye />}
          </IconButton>
        </InputAdornment>
      );
    }

    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          tabIndex={-1}
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {icon}
        </IconButton>
      </InputAdornment>
    );
  };

  const isShowPassword = showPassword
    ? type === "password"
      ? "text"
      : type
    : "password";

  return (
    <InputWrapper size={inputSize}>
      <FormControl error={error} variant="filled">
        <InputLabel error={error}>{label}</InputLabel>
        <FilledInput
          autoComplete="off"
          endAdornment={renderEndAdornment()}
          error={error}
          sx={{
            "&:before": {
              borderBottom: "none",
            },
            "&:after": {
              borderBottom: "none",
            },
          }}
          type={isShowPassword}
          {...otherProps}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
      {tooltipText && (
        <TipWrapper>
          <QuestionTooltip
            className="tooltip"
            placement="right"
            title={tooltipText}
          />
        </TipWrapper>
      )}
    </InputWrapper>
  );
};

export default Input;
