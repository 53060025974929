import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { usePostLoginMutation } from "../../app/user/api";
import { toggleIsRememberMe } from "../../app/user/slice";
import yup from "../../helpers/yup";

export const useSignInForm = () => {
  const dispatch = useAppDispatch();
  const isRememberMe = useAppSelector((state) => state.userSlice.isRememberMe);
  const [postLogin, { isLoading }] = usePostLoginMutation();
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(t("form:validation", { context: "notValidField" }) || "")
        .required(t("form:validation", { context: "requiredField" }) || "")
        .trim(),
      password: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || "")
        // .matches(
        //   REGULAR_EXPRESSIONS.PASSWORD,
        //   VALIDATION_ERRORS.NOT_VALID_PASSWORD
        // )
        .trim(),
    }),
    onSubmit: async (values) => {
      postLogin({ email: values.email, password: values.password });
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [i18n.language]);

  const handleRememberMe = () => dispatch(toggleIsRememberMe());

  return {
    formik,
    handleRememberMe,
    isRememberMe,
    isLoading,
    t,
  };
};
