import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { usePostCaseCreateMutation } from "../../app/case/api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeNewPatientModal } from "../../app/modal/slice";
import PATH from "../../helpers/PATH";
import yup from "../../helpers/yup";
import {
  ECaseCBCTStatus,
  ECaseTab,
  ECaseTRGStatus,
  ECaseType,
} from "../../types/Case";

export const useNewPatientForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading: isUserLoading, user } = useAppSelector(
    (state) => state.userSlice
  );
  const [createCase, { isLoading, isSuccess, data }] =
    usePostCaseCreateMutation();

  const formik = useFormik({
    initialValues: {
      caseType: ECaseTab.ORTHODONTICS,
      patientLastName: "",
      patientFirstName: "",
      patientGender: "",
      patientAge: "",
      doctorFullName: "",
      patientNationality: null,
    },
    validationSchema: yup.object().shape({
      caseType: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      patientLastName: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      patientFirstName: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      patientGender: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      patientAge: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      doctorFullName: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      patientNationality: yup
        .string()
        .nullable()
        .required(t("form:validation", { context: "requiredField" }) || ""),
    }),
    onSubmit: async (values, { resetForm }) => {
      createCase(values);
    },
  });

  useEffect(() => {
    formik.resetForm();
    if (user) {
      formik.setFieldValue(
        "doctorFullName",
        user.firstName + " " + user.lastName
      );
    }
  }, [i18n.language, user]);

  useEffect(() => {
    if (isSuccess && data && data.id) {
      navigate(
        PATH.generate(
          PATH.CASE + PATH.CASE_WITH_TAB,
          data.id.toString(),
          formik.values.caseType
        )
      );
      formik.resetForm();
      dispatch(changeNewPatientModal({ isOpen: false }));
    }
  }, [isSuccess, data]);

  return {
    formik,
    isLoading,
    t,
  };
};
