import styled from "@emotion/styled";

export const AppLayoutWrapper = styled.div`
  box-sizing: border-box;
  background: linear-gradient(180deg, #151c27 0%, #000000 100%);
  min-height: 100vh;
  color: #fff;

  .container {
    max-width: 1247px;
    padding: 0 16px;
    margin: 0 auto;
  }
`;
