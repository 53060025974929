import { ECaseTab, ECaseView } from "../types/Case";

export default {
  HOME: "/",
  CEPHALOMETRIC_ANALYSIS: "/cephalometric-analysis",
  APP_PRIVACY: "/app/privacy",
  APP_PRIVACY_RULE: "/app/privacy/:countryCode/:type",
  APP_SIGNIN: "/app/auth",
  APP_SIGNUP: "/app/auth/sign-up",
  APP_BOOK_A_DEMO: "/app/auth/book-a-demo",
  APP_FORGOT_PASSWORD: "/app/auth/forgot-password",
  APP_CASES: "/app/cases",
  APP_TARIFFS: "/app/tariffs",
  APP_PAYMENT_PROFILE: "/app/payment-profile",

  CASE: "/app/cases/:id",
  CASE_WITH_TAB: "/:tab/",

  FP_CASE_QUESTIONNAIRE: `/app/cases/:id/${ECaseTab.ORTHODONTICS}/${ECaseView.QUESTIONNAIRE}/:id`,
  FP_CASE_QUESTIONNAIRE_LIST: `/app/cases/:id/${ECaseTab.ORTHODONTICS}/${ECaseView.QUESTIONNAIRE}/list`,
  FP_CASE_QUESTIONNAIRE_CREATE: `/app/cases/:id/${ECaseTab.ORTHODONTICS}/${ECaseView.QUESTIONNAIRE}/create`,
  CASE_QUESTIONNAIRE: `${ECaseTab.ORTHODONTICS}/${ECaseView.QUESTIONNAIRE}`,

  FP_CASE_TRG: `/app/cases/:id/${ECaseTab.ORTHODONTICS}/${ECaseView.TRG}/:status`,
  FP_CASE_CBCT: `/app/cases/:id/${ECaseTab.DIAGNOSTICS}/${ECaseView.CBCT}/:status`,
  FP_CASE_RESULTS: `/app/cases/:id/results`,

  CASE_TRG: `${ECaseTab.ORTHODONTICS}/${ECaseView.TRG}/:status`,
  CASE_CBCT: `${ECaseTab.DIAGNOSTICS}/${ECaseView.CBCT}/:status`,

  CASE_BIOMETRICS: `${ECaseTab.ORTHODONTICS}/${ECaseView.BIOMETRICS}`,
  FP_CASE_BIOMETRICS: `/app/cases/:id/${ECaseTab.ORTHODONTICS}/${ECaseView.BIOMETRICS}`,

  CASE_PHOTO_PROTOCOL: `${ECaseTab.ORTHODONTICS}/${ECaseView.PHOTO_PROTOCOL}`,
  CASE_PHOTO_PROTOCOL_LIST: `${ECaseView.PHOTO_PROTOCOL}/list`,
  FP_CASE_PHOTO_PROTOCOL_LIST: `/app/cases/:id/${ECaseTab.ORTHODONTICS}/${ECaseView.PHOTO_PROTOCOL}/list`,

  CASE_PHOTO_PROTOCOL_EDIT: `${ECaseView.PHOTO_PROTOCOL}/edit/:id/:category`,
  FP_CASE_PHOTO_PROTOCOL_EDIT: `/app/cases/:id/${ECaseTab.ORTHODONTICS}/${ECaseView.PHOTO_PROTOCOL}/edit/:id/:category`,

  CASE_PANORAMIC_PHOTO: `${ECaseTab.ORTHODONTICS}/${ECaseView.PANORAMIC_PHOTO}`,
  FP_CASE_PANORAMIC_PHOTO: `/app/cases/:id/${ECaseTab.ORTHODONTICS}/${ECaseView.PANORAMIC_PHOTO}`,

  generate(path: string, ...rest: string[]): string {
    let pathsArray = path.split("/");
    let index = 0;

    pathsArray = pathsArray.map((item) => {
      if (item.indexOf(":") > -1) {
        const url = rest[index];
        index += 1;
        return url;
      }
      return item;
    });

    return pathsArray.join("/");
  },
};
