import styled from "@emotion/styled";

import mainBg from "../../../views/CephalometricAnalysis/img/main-bg.jpg";

export const PageHeaderWrapper = styled.header`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0;
  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  .tryFree {
    border-radius: 80px;
    border: 1px solid #236bcf;
    background: #236bcf;
    font-family: Manrope;
    display: inline-block;
    flex-shrink: 0;
    white-space: nowrap;
    padding: 6px 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: slide 0.3s;
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
    z-index: 10;
    menu {
      li {
        a {
          color: #236bcf;
        }
      }
    }
    button {
      svg path {
        stroke: #000 !important;
      }
    }
  }
  .logo {
    display: flex;
    align-items: center;
    z-index: 4;
    max-width: 258px;
  }
  .menu-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: none;
  }
  .header-container {
    position: relative;
    max-width: 1400px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .header {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  menu {
    display: flex;
    align-items: center;
    justify-content: right;
    list-style-type: none;
    gap: 20px;
    padding: 0;
    margin: 0;
    max-width: 730px;
    width: 100%;
    li {
      margin-left: 20px;
      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        transition: color, opacity 0.3s ease;
        &:hover {
          color: var(--accent-color);
          opacity: 0.5;
        }
        //&.active {
        //  color: var(--accent-color);
        //}
      }
    }
  }
  .signIn-button {
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 10px 9px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    transition: all 0.3s ease;
    span {
      transition: all 0.3s ease;
      margin-left: 9px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
    &:hover {
      border-color: var(--accent-color);
      span {
        color: var(--accent-color);
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .header {
      max-width: 872px;
      margin: 0 auto;
    }
    .logo {
      max-width: 126px;
    }
    menu {
      li {
        a {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    .MuiButtonBase-root {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1200px) {
    .header {
      padding: 16px 0;
      justify-content: space-between;
    }
    .menu-btn {
      display: block;
      position: absolute;
      right: 20px;
    }
    .logo {
      flex-shrink: 0;
    }
    menu {
      max-width: 200px;
      li:nth-of-type(1),
      li:nth-of-type(2),
      li:nth-of-type(3),
      li:nth-of-type(4),
      li:nth-of-type(5),
      li:nth-of-type(6) {
        display: none;
      }
    }
  }
`;
