import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { API_TAGS } from "../../helpers/constants";
import { IImg } from "../../types/Case";
import { ITRGMask } from "../../types/TRG";
import { caseApi } from "../case/api";
import { trgApi } from "../trg/api";

const initialState: {
  trgImage: IImg | null;
  trgMask: ITRGMask | null;
  isTRGImageLoading: boolean;
  isTRGMaskLoading: boolean;
} = {
  trgImage: null,
  trgMask: null,

  isTRGImageLoading: false,
  isTRGMaskLoading: false,
};

const trgSlice = createSlice({
  name: API_TAGS.TRG,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ---------------------------------------------------------------------
      .addMatcher(
        trgApi.endpoints.getCasesTRGSDelineationAI.matchPending,
        (state, action) => {
          state.isTRGMaskLoading = true;
        }
      )
      .addMatcher(
        trgApi.endpoints.getCasesTRGSDelineationAI.matchFulfilled,
        (state, action) => {
          state.trgMask = action.payload;
          state.isTRGMaskLoading = false;
        }
      )
      .addMatcher(
        trgApi.endpoints.getCasesTRGSDelineationAI.matchRejected,
        (state, action) => {
          state.trgMask = null;
          state.isTRGMaskLoading = false;
        }
      )

      .addMatcher(
        trgApi.endpoints.getCasesTRGSDelineation.matchPending,
        (state, action) => {
          state.isTRGMaskLoading = true;
        }
      )
      .addMatcher(
        trgApi.endpoints.getCasesTRGSDelineation.matchFulfilled,
        (state, action) => {
          state.trgMask = action.payload;
          state.isTRGMaskLoading = false;
        }
      )
      .addMatcher(
        trgApi.endpoints.getCasesTRGSDelineation.matchRejected,
        (state, action) => {
          state.trgMask = null;
          state.isTRGMaskLoading = false;
        }
      )
      // ---------------------------------------------------------------------
      .addMatcher(
        trgApi.endpoints.getTRGSImage.matchPending,
        (state, action) => {
          state.isTRGImageLoading = true;
        }
      )
      .addMatcher(
        trgApi.endpoints.getTRGSImage.matchFulfilled,
        (state, action) => {
          state.trgImage = action.payload;
          state.isTRGImageLoading = false;
        }
      )
      .addMatcher(
        trgApi.endpoints.getTRGSImage.matchRejected,
        (state, action) => {
          state.trgImage = null;
          state.isTRGImageLoading = false;
        }
      )
      // ---------------------------------------------------------------------
      .addMatcher(caseApi.endpoints.getCase.matchPending, (state, action) => {
        state.trgImage = null;
        state.trgMask = null;
      });
  },
});

export const {} = trgSlice.actions;
export default trgSlice.reducer;
