import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Cropper, CropperRef } from "react-mobile-cropper";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeImageCropperModal } from "../../app/modal/slice";
import Icon from "../../icons";

import { ImageCropperWrapper } from "./styles";

import "react-mobile-cropper/dist/style.css";

const ImageCropperModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const cropperRef = useRef<CropperRef>();
  const { isOpen, image } = useAppSelector(
    (state) => state.modalSlice.imageCropper
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseModal = () => {
    dispatch(changeImageCropperModal({ isOpen: false }));
  };

  const onChange = () => {
    if (cropperRef.current) {
      const canvasRef = cropperRef.current?.getCanvas();
      if (canvasRef) {
        const img = canvasRef.toDataURL();
        dispatch(
          changeImageCropperModal({
            isOpen: false,
            image: img,
          })
        );
      }
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="sm"
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
      fullWidth
    >
      <ImageCropperWrapper>
        <button
          className="close-button"
          type="button"
          onClick={handleCloseModal}
        >
          <Icon icon="close" />
        </button>
        <DialogContent>
          <Box sx={{ height: 700 }}>
            <Box sx={{ height: 600, margin: "0 auto" }}>
              <Cropper
                ref={cropperRef}
                src={image}
                stencilProps={{ overlayClassName: "overlay" }}
              />
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingButton
                sx={{ maxWidth: 295, fontSize: "16px", fontWeight: 500 }}
                type="submit"
                variant="app-button-sm"
                onClick={onChange}
              >
                {t("common:save")}
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </ImageCropperWrapper>
    </Dialog>
  );
};

export default ImageCropperModal;
