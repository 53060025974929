import cookies from "react-cookies";
import { createSlice } from "@reduxjs/toolkit";

import { API_TAGS, COOKIES } from "../../helpers/constants";
import { customHistory } from "../../helpers/CustomerBrowserRouter";
import PATH from "../../helpers/PATH";
import { IUserSlice } from "../../types/User";

import { userApi } from "./api";

const SignUpForm = {
  email: "",
  phoneNumber: "",
  lastName: "",
  firstName: "",
  clinicName: "",
  password: "",
  matchingPassword: "",
};

const initialState: IUserSlice = {
  isLoading: false,
  isSignUpLoading: false,
  isChangePasswordLoading: false,
  user: null,
  isRememberMe: false,
  token: cookies.load(COOKIES.TOKEN) || null,
  signUpForm: Object.assign(SignUpForm),
};

const userSlice = createSlice({
  name: API_TAGS.USER,
  initialState,
  reducers: {
    signOut: (state: IUserSlice) => {
      cookies.remove(COOKIES.TOKEN, { path: "/" });
      state.user = null;
      state.token = null;
      customHistory.push(PATH.APP_SIGNIN);
    },
    toggleIsRememberMe: (state: IUserSlice) => {
      state.isRememberMe = !state.isRememberMe;
    },
    fillInSignUpForm: (state: IUserSlice, action) => {
      state.signUpForm = { ...state.signUpForm, ...action.payload };
    },
    clearSignUpForm: (state: IUserSlice) => {
      state.signUpForm = Object.assign(SignUpForm);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.postLogin.matchPending, (state, action) => {
        state.isLoading = true;
      })
      .addMatcher(
        userApi.endpoints.postLogin.matchFulfilled,
        (state, action) => {
          const token = action.payload.token;
          cookies.save(COOKIES.TOKEN, token, { path: "/" });
          // if (state.isRememberMe) {
          //   cookies.save(COOKIES.TOKEN, token, { path: "/" });
          // }
          state.token = token;
          state.isLoading = false;
          const url = cookies.load(COOKIES.REDIRECT_URL) || "";
          if (url) {
            cookies.remove(COOKIES.REDIRECT_URL, { path: "/" });
          }
          customHistory.push(url.length ? url : PATH.APP_CASES);
        }
      )
      .addMatcher(
        userApi.endpoints.postLogin.matchRejected,
        (state, action) => {
          state.isLoading = false;
        }
      )
      // -------------------------------------------------------------------------
      .addMatcher(
        userApi.endpoints.postCheckSignUp.matchPending,
        (state, action) => {
          state.isSignUpLoading = true;
        }
      )
      .addMatcher(
        userApi.endpoints.postCheckSignUp.matchFulfilled,
        (state, action) => {
          state.isSignUpLoading = false;
        }
      )
      .addMatcher(
        userApi.endpoints.postCheckSignUp.matchRejected,
        (state, action) => {
          state.isSignUpLoading = false;
        }
      )
      // -------------------------------------------------------------------------
      .addMatcher(
        userApi.endpoints.postConfirmationCode.matchPending,
        (state, action) => {
          state.isSignUpLoading = true;
        }
      )
      .addMatcher(
        userApi.endpoints.postConfirmationCode.matchFulfilled,
        (state, action) => {
          state.isSignUpLoading = false;
        }
      )
      .addMatcher(
        userApi.endpoints.postConfirmationCode.matchRejected,
        (state, action) => {
          state.isSignUpLoading = false;
        }
      )
      // -------------------------------------------------------------------------
      .addMatcher(
        userApi.endpoints.postSignUp.matchPending,
        (state, action) => {
          state.isSignUpLoading = true;
        }
      )
      .addMatcher(
        userApi.endpoints.postSignUp.matchFulfilled,
        (state, action) => {
          state.isSignUpLoading = false;
        }
      )
      .addMatcher(
        userApi.endpoints.postSignUp.matchRejected,
        (state, action) => {
          state.isSignUpLoading = false;
        }
      )
      // -------------------------------------------------------------------------
      .addMatcher(
        userApi.endpoints.postForgotPassword.matchPending,
        (state, action) => {
          state.isChangePasswordLoading = true;
        }
      )
      .addMatcher(
        userApi.endpoints.postForgotPassword.matchFulfilled,
        (state, action) => {
          state.isChangePasswordLoading = false;
        }
      )
      .addMatcher(
        userApi.endpoints.postForgotPassword.matchRejected,
        (state, action) => {
          state.isChangePasswordLoading = false;
        }
      )
      // -------------------------------------------------------------------------
      .addMatcher(
        userApi.endpoints.postChangePassword.matchPending,
        (state, action) => {
          state.isChangePasswordLoading = true;
        }
      )
      .addMatcher(
        userApi.endpoints.postChangePassword.matchFulfilled,
        (state, action) => {
          state.isChangePasswordLoading = false;
        }
      )
      .addMatcher(
        userApi.endpoints.postChangePassword.matchRejected,
        (state, action) => {
          state.isChangePasswordLoading = false;
        }
      )
      // -------------------------------------------------------------------------
      .addMatcher(
        userApi.endpoints.getCurrentUser.matchPending,
        (state, action) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        userApi.endpoints.getCurrentUser.matchFulfilled,
        (state, action) => {
          state.isLoading = false;
          state.user = action.payload || null;
        }
      )
      .addMatcher(
        userApi.endpoints.getCurrentUser.matchRejected,
        (state, action) => {
          state.isLoading = false;
        }
      );
  },
});

export const {
  signOut,
  toggleIsRememberMe,
  fillInSignUpForm,
  clearSignUpForm,
} = userSlice.actions;
export default userSlice.reducer;
