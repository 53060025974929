import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import PATH from "../../helpers/PATH";

import PageFooter from "./Footer";
import PageHeader from "./Header";
import Header2nd from "./Header2nd";
import MobileMenu from "./MobileMenu";
import { PageLayoutWrapper } from "./styles";

const PageLayout = () => {
  const location = useLocation();
  const [isMenuActive, setIsMenuActive] = useState(false);

  return (
    <PageLayoutWrapper className={`${isMenuActive ? "menu-active" : ""}`}>
      <Box bgcolor="gray.100" height="100%">
        <Header2nd
          isMenuActive={isMenuActive}
          setIsMenuActive={setIsMenuActive}
        />
        <Outlet />
        <PageFooter />
      </Box>
      <MobileMenu
        isMenuActive={isMenuActive}
        setIsMenuActive={setIsMenuActive}
      />
    </PageLayoutWrapper>
  );
};

export default PageLayout;
