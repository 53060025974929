import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeNewPatientModal } from "../../app/modal/slice";
import AppSelect from "../../components/AppSelect";
import Input from "../../components/Input";
import { GENDER_OPTIONS, RACE_OPTIONS } from "../../helpers/options";
import Icon from "../../icons";
import { ECaseTab } from "../../types/Case";

import { NewPatientWrapper } from "./styles";
import { useNewPatientForm } from "./useNewPatientForm";

const PhoneConfirmationModal = () => {
  const { formik, isLoading, t } = useNewPatientForm();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.modalSlice.newPatient);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleDelete = () => {};

  const handleCloseModal = () => {
    dispatch(changeNewPatientModal({ isOpen: false }));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
      fullWidth
    >
      <NewPatientWrapper>
        <button
          className="close-button"
          type="button"
          onClick={handleCloseModal}
        >
          <Icon icon="close" />
        </button>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid justifyContent="center" spacing={2} container>
              <Grid sx={{ marginBottom: 2, marginLeft: 1 }} xs={12} item>
                <Typography variant="h3">{t("form:addNewPatient")}</Typography>
              </Grid>
              <Grid xs={12} item>
                <Input
                  error={
                    formik.touched.patientLastName &&
                    !!formik.errors.patientLastName
                  }
                  helperText={
                    (formik.touched.patientLastName &&
                      formik.errors.patientLastName) ||
                    ""
                  }
                  label={t("common:lastName", { suffix: "" }) || ""}
                  name="patientLastName"
                  value={formik.values.patientLastName}
                  variant="filled"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  error={
                    formik.touched.patientFirstName &&
                    !!formik.errors.patientFirstName
                  }
                  helperText={
                    (formik.touched.patientFirstName &&
                      formik.errors.patientFirstName) ||
                    ""
                  }
                  label={t("common:name", { suffix: "" }) || ""}
                  name="patientFirstName"
                  type="text"
                  value={formik.values.patientFirstName}
                  variant="filled"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={6} item>
                <AppSelect
                  error={
                    formik.touched.patientGender &&
                    !!formik.errors.patientGender
                  }
                  helperText={
                    (formik.touched.patientGender &&
                      formik.errors.patientGender) ||
                    ""
                  }
                  label={t("common:gender") || ""}
                  name="patientGender"
                  options={GENDER_OPTIONS.map((gender) => {
                    return {
                      ...gender,
                      label: t(`app:${gender.label}`),
                    };
                  })}
                  type="text"
                  value={formik.values.patientGender}
                  variant="filled"
                  onChange={(v) => formik.setFieldValue("patientGender", v)}
                />
              </Grid>
              <Grid xs={6} item>
                <Input
                  error={
                    formik.touched.patientAge && !!formik.errors.patientAge
                  }
                  helperText={
                    (formik.touched.patientAge && formik.errors.patientAge) ||
                    ""
                  }
                  label={t("common:age") || ""}
                  name="patientAge"
                  type="number"
                  value={formik.values.patientAge}
                  variant="filled"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <AppSelect
                  error={
                    formik.touched.patientNationality &&
                    !!formik.errors.patientNationality
                  }
                  helperText={
                    (formik.touched.patientNationality &&
                      formik.errors.patientNationality) ||
                    ""
                  }
                  label={t("common:nationality") || ""}
                  name="patientGender"
                  options={RACE_OPTIONS.map((race) => {
                    return {
                      ...race,
                      label: t("app:race", { context: race.label }),
                    };
                  })}
                  type="text"
                  value={formik.values.patientNationality}
                  variant="filled"
                  onChange={(v) =>
                    formik.setFieldValue("patientNationality", v)
                  }
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  error={
                    formik.touched.doctorFullName &&
                    !!formik.errors.doctorFullName
                  }
                  helperText={
                    (formik.touched.doctorFullName &&
                      formik.errors.doctorFullName) ||
                    ""
                  }
                  label={t("common:doctor") || ""}
                  name="doctorFullName"
                  type="text"
                  value={formik.values.doctorFullName}
                  variant="filled"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              {/*<Grid sx={{ marginBottom: 1, marginTop: 1 }} xs={12} item>*/}
              {/*  <Typography*/}
              {/*    color={*/}
              {/*      !(formik.touched.caseType && !!formik.errors.caseType)*/}
              {/*        ? ""*/}
              {/*        : "#ed5f68"*/}
              {/*    }*/}
              {/*    variant="h3"*/}
              {/*  >*/}
              {/*    {t("form:chooseTypeOfResearch")}*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}
              {/*<Grid sx={{ marginBottom: 1 }} xs={12} item>*/}
              {/*  <Box*/}
              {/*    sx={{*/}
              {/*      display: "flex",*/}
              {/*      justifyContent: "center",*/}
              {/*      alignItems: "center",*/}
              {/*      ".type-checkbox:first-of-type": {*/}
              {/*        marginRight: "44px",*/}
              {/*      },*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <label className="type-checkbox">*/}
              {/*      <input*/}
              {/*        checked={formik.values.caseType === ECaseTab.ORTHODONTICS}*/}
              {/*        type="checkbox"*/}
              {/*        value={ECaseTab.ORTHODONTICS}*/}
              {/*        onChange={() =>*/}
              {/*          formik.setFieldValue("caseType", ECaseTab.ORTHODONTICS)*/}
              {/*        }*/}
              {/*      />*/}
              {/*      <div>{t("common:orthodontics")}</div>*/}
              {/*    </label>*/}
              {/*    <label className="type-checkbox">*/}
              {/*      <input*/}
              {/*        checked={formik.values.caseType === ECaseTab.DIAGNOSTICS}*/}
              {/*        type="checkbox"*/}
              {/*        value={ECaseTab.DIAGNOSTICS}*/}
              {/*        onChange={() =>*/}
              {/*          formik.setFieldValue("caseType", ECaseTab.DIAGNOSTICS)*/}
              {/*        }*/}
              {/*      />*/}
              {/*      <div>{t("common:diagnostics")}</div>*/}
              {/*    </label>*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
              <Grid xs={12} item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton
                    loading={isLoading}
                    sx={{ maxWidth: 295, fontSize: "16px", fontWeight: 500 }}
                    type="submit"
                    variant="app-button-sm"
                    onClick={handleDelete}
                  >
                    {t("common:create")}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </NewPatientWrapper>
    </Dialog>
  );
};

export default PhoneConfirmationModal;
