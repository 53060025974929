import styled from "@emotion/styled";

export const AppSelectWrapper = styled.div`
  box-sizing: border-box;
  max-width: 900px;
  display: flex;
  align-items: flex-start;
  .MuiSelect-select {
    text-align: left;
  }
  .MuiInputBase-input {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #fff;
    background-color: #253041;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 999950000s ease-in-out 0s,
        color 50000s ease-in-out 0s;
      transition-delay: background-color 999950000s, color 50000s;
    }
  }
  .MuiInputLabel-root {
    color: #fff;
  }
  .MuiInputLabel-root.Mui-focused,
  .MuiInputLabel-root.Mui-filled,
  .MuiInputLabel-shrink {
    color: #7c859a;
    font-weight: 400;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: -0.03em;
    transform: translate(12px, 12px) scale(0.75);
  }
  .MuiFormControl-root {
  }
  .MuiInputLabel-root.Mui-error,
  .MuiInputLabel-root.Mui-focused.Mui-error {
    color: #ed5f68;
  }
  .MuiButtonBase-root {
    padding: 14px;
  }
  .MuiInputBase-root,
  .MuiInputBase-root:hover {
    overflow: hidden;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #fff;
    &.Mui-error {
      border-color: #ed5f68;
    }
  }
  .MuiInputBase-root,
  .MuiInputBase-root.Mui-focused,
  .MuiInputBase-root:hover {
    background: #253041;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    &:before,
    &:after {
      display: none;
    }
    .MuiButtonBase-root {
      background: #253041;
    }
  }
  .Mui-error {
    .MuiIconButton-root {
      color: #ed5f68;
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiIconButton-root {
    color: #8f95a1;
  }
  .MuiFormHelperText-root {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 13px;
    margin-bottom: 10px;
    &.Mui-error {
      color: #ed5f68;
    }
  }
  .MuiSelect-iconFilled {
    fill: #8f95a1;
  }

  @media screen and (max-width: 600px) {
    .tooltip {
      display: none;
    }
  }
`;

export const TipWrapper = styled.div`
  margin-top: 4px;
`;
