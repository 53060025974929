import React from "react";
import { HiEnvelope } from "react-icons/hi2";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, Typography } from "@mui/material";

import headSkeletImg from "../../assets/images/head-skelet.png";
import AppCheckbox from "../../components/AppCheckbox";
import Input from "../../components/Input";
import PhoneInput from "../../components/PhoneInput";
import PATH from "../../helpers/PATH";
import Icon from "../../icons";
import { ECountryCode } from "../../types/Common";
import { EPrivacyType } from "../../types/Privacy";

import { SignUpWrapper } from "./styles";
import { useSignUpForm } from "./useSignUpForm";

const SignUp = () => {
  const { formik, isAgree, handleIsAgree, t, Trans } = useSignUpForm();

  return (
    <SignUpWrapper>
      <form onSubmit={formik.handleSubmit}>
        <Grid spacing={2} container>
          <Grid sx={{ marginBottom: 2, marginLeft: 1 }} xs={12} item>
            <Typography variant="h3">
              {t("common:create", { context: "accountYou" })}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.email && !!formik.errors.email}
              helperText={(formik.touched.email && formik.errors.email) || ""}
              icon={<HiEnvelope />}
              label={t("common:email", { suffix: "" }) || ""}
              name="email"
              tooltipText={
                t("form:validation", { context: "enterMailWithExample" }) || ""
              }
              type="email"
              value={formik.values.email}
              variant="filled"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.lastName && !!formik.errors.lastName}
              helperText={
                (formik.touched.lastName && formik.errors.lastName) || ""
              }
              icon={<Icon icon="user-contacts" />}
              label={t("common:lastName", { suffix: "" }) || ""}
              name="lastName"
              tooltipText={
                t("form:validation", { context: "enterLastName" }) || ""
              }
              type="text"
              value={formik.values.lastName}
              variant="filled"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.firstName && !!formik.errors.firstName}
              helperText={
                (formik.touched.firstName && formik.errors.firstName) || ""
              }
              icon={<Icon icon="user-contacts" />}
              label={t("common:name", { suffix: "" }) || ""}
              name="firstName"
              tooltipText={t("form:validation", { context: "enterName" }) || ""}
              type="text"
              value={formik.values.firstName}
              variant="filled"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.clinicName && !!formik.errors.clinicName}
              helperText={
                (formik.touched.clinicName && formik.errors.clinicName) || ""
              }
              icon={<Icon icon="user-contacts" />}
              label={t("common:clinicName") || ""}
              name="clinicName"
              tooltipText={
                t("form:validation", { context: "enterClinicName" }) || ""
              }
              type="text"
              value={formik.values.clinicName}
              variant="filled"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <PhoneInput
              inputStyle={{ fontSize: "16px" }}
              placeholder={t("common:phoneNum") || ""}
              value={formik.values.phoneNumber}
              onChange={(value) => formik.setFieldValue("phoneNumber", value)}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.password && !!formik.errors.password}
              helperText={
                (formik.touched.password && formik.errors.password) || ""
              }
              icon={<HiEnvelope />}
              label={t("form:validation", { context: "enterPassword" }) || ""}
              name="password"
              tooltipText={
                t("form:validation", { context: "enterPassword" }) || ""
              }
              type="password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={
                formik.touched.matchingPassword &&
                !!formik.errors.matchingPassword
              }
              helperText={
                (formik.touched.matchingPassword &&
                  formik.errors.matchingPassword) ||
                ""
              }
              icon={<HiEnvelope />}
              label={t("form:validation", { context: "repeatPassword" }) || ""}
              name="matchingPassword"
              tooltipText={
                t("form:validation", { context: "repeatPassword" }) || ""
              }
              type="password"
              value={formik.values.matchingPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="space-between"
            paddingBottom={2}
            xs={12}
            container
            item
          >
            <AppCheckbox
              checked={isAgree}
              label={
                <Trans i18nKey="form:agreements">
                  <span className="text">
                    Я принимаю условия
                    <Link
                      className="text text--blue text--semi-bold text--inline"
                      target="_blank"
                      to={PATH.generate(
                        PATH.APP_PRIVACY_RULE,
                        ECountryCode.GLOBAL,
                        EPrivacyType.TERMS
                      )}
                    >
                      Пользовательского <br /> соглашения
                    </Link>
                    и
                    <Link
                      className="text text--blue text--semi-bold text--inline"
                      target="_blank"
                      to={PATH.generate(
                        PATH.APP_PRIVACY_RULE,
                        ECountryCode.GLOBAL,
                        EPrivacyType.PRIVACY_POLICY
                      )}
                    >
                      Политику конфиденциальности.
                    </Link>
                  </span>
                </Trans>
              }
              onChange={handleIsAgree}
            />
          </Grid>
          <Grid md={11} xs={12} item>
            <LoadingButton type="submit" variant="app-button">
              {t("common:signUp")}
            </LoadingButton>
          </Grid>
          <Grid alignItems="flex-start" md={11} xs={12} item>
            <Box sx={{ textAlign: "center" }}>
              <p className="text">
                <span>{t("form:haveAnAccount")}</span> &nbsp;
                <Link
                  className="text text--bold text--primary"
                  to={PATH.APP_SIGNIN}
                >
                  {t("common:signIn", { context: "you" })}
                </Link>
              </p>
            </Box>
          </Grid>
        </Grid>
      </form>
      <div className="info">
        <Trans context="block" i18nKey="page:opportunity">
          <p>
            Мы даем вам возможность бесплатно провести первый анализ с помощью
            AIdentis. <br />
            Полная функциональность. <br />
            Нет ограничения во времени.
          </p>
          <ul>
            <li>
              Расстановка цефалометрических точек с помощью нейросетевых
              алгоритмов
            </li>
            <li>Автоматический расчет всех измерений</li>
            <li>Калибровка для достижения наилучших результатов</li>
            <li>Возможность выбора автора</li>
            <li>Просмотр и загрузка отчетов</li>
          </ul>
        </Trans>
        <div
          className="info-img"
          style={{ backgroundImage: `url(${headSkeletImg})` }}
        ></div>
      </div>
    </SignUpWrapper>
  );
};

export default SignUp;
