export const CASE_FILTER_OPTIONS = [
  {
    value: "FAVORITE",
    label: "Избранное",
  },
  {
    value: "ARCHIVED",
    label: "Архив",
  },
];

export const GENDER_OPTIONS = [
  {
    value: "MALE",
    label: "MALE",
  },
  {
    value: "FEMALE",
    label: "FEMALE",
  },
];

export const RACE_OPTIONS = [
  {
    value: "AFRICAN",
    label: "african",
  },
  {
    value: "EUROPEAN",
    label: "european",
  },
  {
    value: "ASIAN",
    label: "asian",
  },
  {
    value: "HISPANIC",
    label: "hispanic",
  },
];
