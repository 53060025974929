import styled from "@emotion/styled";

export const PrivacyLayoutWrapper = styled.div`
  box-sizing: border-box;
  background: transparent;
  color: #fff;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  .menu {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    a {
      display: block;
      background: #192230;
      color: #fff;
      text-decoration: none;
      padding: 20px;
      opacity: 0.4;
      transition: 0.3s ease all;
      &.active {
        opacity: 1;
      }
    }
  }
`;
