import styled from "@emotion/styled";

import mainBg from "../../assets/images/page/main-bg.jpg";
import priceCard from "../../assets/images/page/price-card.png";
import servicesArrow from "../../assets/images/page/services-arrow.svg";

export const WelcomeWrapper = styled.main`
  a {
    text-decoration: none;
  }

  .container {
    max-width: 1400px;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
  }

  .title2 {
    color: #236bcf;
    font-size: 32px;
    font-weight: 500;
  }

  .link-all {
  }

  .wrapper {
  }

  .header-main {
    background-image: url(${mainBg});
    background-repeat: no-repeat;
    background-size: cover;
  }

  /*              SECTION-MAIN               */

  .section-main {
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 50px;
    &__inner {
      max-width: 958px;
    }
    &__title2 {
      color: #f0f0f0;
      font-family: "Neue Machina";
      font-size: 37px;
      line-height: 39px;
      letter-spacing: 1.2px;
      margin-bottom: 28px;
      max-width: 781px;
    }
    &__text {
      color: #fff;
      font-size: 22px;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: 0.44px;
      margin-bottom: 50px;
    }
    &__link {
      color: #fff;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      padding: 21px 0;
      display: inline-block;
      width: 373px;
      border-radius: 80px;
      border: 1px solid #fff;
      transition: all 0.3s ease;
      &:hover {
        border-color: #fff;
        background: #fff;
        color: #236bcf;
      }
      &:focus {
        border-color: #9bc5ff;
        background: #9bc5ff;
        color: #236bcf;
      }
    }
  }

  /*              SERVICES               */

  .services {
    padding: 52px 0 40px;
    color: #236bcf;
    &__item-wrap {
      padding: 72px 0 82px;
      display: flex;
      overflow: hidden;
      flex-direction: column;
    }
    &__item {
      cursor: pointer;
      padding-left: 50px;
      max-width: 850px;
      position: relative;
      align-self: flex-end;
      z-index: 1;
      &::before {
        transition: transform 0.3s ease;
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        background-image: url(${servicesArrow});
        background-repeat: no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
      }
      &.active::before {
        transform: rotate(45deg);
      }
      &-imgs {
        display: flex;
        justify-content: space-between;
        height: 0;
        opacity: 0;
        transition: all 0.5s ease;
        &.active {
          height: 406px;
          opacity: 1;
          margin-top: 50px;
        }
        & > div {
          width: 32%;
        }
      }
    }
    &__item--decor {
      border-top: 1px solid #236bcf;
      border-bottom: 1px solid #236bcf;
    }
    &__item-title2 {
      margin-top: 0;
      font-family: "Neue Machina";
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 16px;
    }
    &__item-text {
      font-family: "Manrope";
      font-size: 22px;
      font-weight: 400;
      line-height: 34px;
      margin-bottom: 0;
      letter-spacing: 0.44px;
    }
  }

  /*              VIDEO               */

  .video {
    position: relative;
    width: 100%;
    background-color: #0d0a2c;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title2 {
      color: #fff;
      font-family: "Manrope";
      font-size: 32px;
      letter-spacing: 0.64px;
      text-align: center;
    }
  }

  /*              PRESENTATION               */

  .presentation {
    padding: 62px 0 140px;
    color: #236bcf;
    &__info {
      display: flex;
      justify-content: space-between;
      column-gap: 50px;
    }
    &__descr {
      max-width: 665px;
      display: flex;
      flex-direction: column;
    }
    &__about {
      margin: auto 0;
    }
    &__title2 {
      margin-top: 0;
      font-family: "Manrope";
      font-size: 32px;
      line-height: 34px;
      font-weight: 500;
      letter-spacing: 0.96px;
      margin-bottom: 30px;
    }
    &__text {
      font-family: "Manrope";
      font-size: 22px;
      margin-bottom: 48px;
      span {
        position: relative;
        display: block;
        margin-bottom: 4px;
        padding-left: 20px;
        &:before {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background-color: #236bcf;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &--bottom {
        margin-top: auto;
      }
    }
    &__slide {
      position: relative;
      .slick-slider {
      }
      .slick-list {
        width: 595px;
        height: 842px;
      }
      .slick-arrow {
        &:before {
          content: none;
          display: none;
        }
        top: unset;
        bottom: -96px;
        border: 1px solid #236bcf;
        width: 56px;
        height: 56px;
        border-radius: 56px;
        path {
          fill: #236bcf;
        }
      }
      .slick-prev {
        left: 0;
      }
      .slick-next {
        left: 292px;
      }
      .slick-dots {
        bottom: -50px;
        left: 65px;
        width: 218px;
        li.slick-active button:before {
          color: #236bcf;
        }
      }
    }
    &__link {
      color: #236bcf;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      padding: 21px 0;
      display: inline-block;
      width: 373px;
      border-radius: 80px;
      border: 1px solid #236bcf;
      transition: all 0.3s ease;
      &:hover {
        border-color: #236bcf;
        background: #236bcf;
        color: #fff;
      }
      &:focus {
        color: #fff;
        border-color: #236bcf;
        background: #164485;
      }
    }
  }

  /*              PRICES               */

  .prices-container {
    padding: 0 35px;
  }

  .prices {
    background-color: #0d0a2c;
    padding: 47px 0 120px;
    &__title2 {
      color: #badaff;
      font-family: "Manrope";
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0.64px;
      margin-bottom: 62px;
    }
    &__cards {
      display: flex;
      justify-content: center;
      gap: 30px;
      align-items: stretch;
      flex-wrap: wrap;
    }
    &__card-wrap {
      border-radius: 30px;
      padding: 38px 47px 75px;
      max-width: 400px;
      position: relative;
      background-image: url(${priceCard});
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__card {
      position: relative;
      min-height: 266px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__card-date {
      padding-left: 15px;
      color: #fff;
      text-align: justify;
      font-family: "Manrope";
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 56px;
    }
    &__card-price {
      padding-left: 15px;
      color: #fff;
      font-family: "Neue Machina";
      font-size: 52px;
      margin-bottom: 52px;
    }
    &__card-span {
      display: block;
      text-align: justify;
      font-family: "Manrope";
      font-size: 18px;
      line-height: 36px;
    }
    &__card-descr {
      padding-left: 15px;
      color: #fff;
      font-family: "Manrope";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 32px;
      span {
        font-weight: 700;
      }
    }
    &__card-link {
      color: #fff;
      text-align: justify;
      font-family: "Manrope";
      font-size: 18px;
      font-weight: 500;
      line-height: 36px;
      display: inline-block;
      max-width: 306px;
      padding: 10px 115px;
      border-radius: 60px;
      border: 1px solid #fff;
      justify-self: flex-end;
      text-align: center;
      margin-top: auto;
    }
  }

  .mission {
    margin-bottom: 130px;
    &__about {
      display: flex;
      margin-bottom: 120px;
      column-gap: 283px;
    }
    &__title2 {
      color: #badaff;
      font-family: "Manrope";
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0.64px;
    }
    &__descr {
      color: #badaff;
      text-align: justify;
      font-family: "Neue Machina";
      font-size: 31px;
      line-height: 50px;
      letter-spacing: 0.62px;
      text-shadow: 0 4px 30px rgba(49, 144, 255, 0.5);
    }
    &__items {
      display: flex;
      justify-content: space-between;
      row-gap: 35px;
      align-items: center;
      column-gap: 101px;
    }
    &__item {
    }
    &__item-title2 {
      color: #badaff;
      text-align: justify;
      font-family: "Manrope";
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 22px;
    }
    &__item-text {
      color: #badaff;
      text-align: justify;
      font-family: "Manrope";
      font-size: 20px;
      line-height: 36px;
    }
  }

  @media (max-width: 1600px) {
    .container {
      max-width: 872px;
    }
    .section-main {
      &__title2 {
        font-size: 24px;
        line-height: 26px;
        margin-top: 0;
        margin-bottom: 20px;
      }
      &__text {
        font-size: 12px;
        line-height: 14px;
        max-width: 500px;
        margin: 0 0 20px 0;
      }
      &__link {
        font-size: 12px;
        font-weight: 600;
        padding: 12px 48px;
        max-width: 273px;
      }
    }
    .title2 {
      font-size: 20px;
    }
    .services {
      &__item {
        max-width: 392px;
        padding-left: 25px;
        &:before {
          top: 0;
          width: 11px;
          height: 11px;
        }
      }
      &__item-text {
        margin-top: 0;
        font-size: 12px;
        line-height: 16px;
      }
      &__item-title2 {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 20px;
      }
      &__item-wrap {
        padding-bottom: 38px;
        padding-top: 40px;
      }
      &__item-imgs {
        font-size: 12px;
        &.active {
          height: 222px;
        }
      }
    }
    .mission {
      margin-bottom: 58px;
      &__title2 {
        font-size: 20px;
        line-height: 22px;
      }
      &__about {
        margin-bottom: 80px;
        column-gap: 116px;
      }
      &__descr {
        padding-top: 30px;
        max-width: 680px;
        width: 100%;
        font-size: 18px;
        line-height: 22px;
      }
      &__items {
        column-gap: 66px;
      }
      &__item {
      }
      &__item-title2 {
        font-size: 18px;
        line-height: 20px;
      }
      &__item-text {
        font-size: 12px;
        line-height: 20px;
      }
    }
    .prices {
      &__title2 {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 32px;
      }
      &__cards {
        gap: 20px;
        justify-content: center;
      }
      &__card-wrap {
        padding: 28px 32px;
        max-width: 249px;
      }
      &__card-date {
        padding-left: 0;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 32px;
      }
      &__card-price {
        padding-left: 0;
        font-size: 26px;
        margin-bottom: 26px;
      }
      &__card-span {
        padding-left: 0;
        font-size: 12px;
        line-height: 14px;
      }
      &__card-descr {
        padding-left: 0;
        font-size: 12px;
        line-height: 20px;
      }
      &__card-link {
        font-size: 12px;
        line-height: 14px;
        max-width: 186px;
        width: 100%;
        padding: 15px 0;
        text-align: center;
      }
    }
    .presentation {
      padding-bottom: 96px;
      &__title2 {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 26px;
      }
      &__text {
        margin-bottom: 30px;
        span {
          font-size: 12px;
          padding-left: 7px;
          &:before {
            width: 2px;
            height: 2px;
          }
        }
      }
      &__link {
        padding: 12px 48px;
        font-size: 12px;
        max-width: 222px;
      }
      .slick-list {
        width: 366px;
        height: 518px;
      }
      .slick-arrow {
        width: 35px;
        height: 35px;
        bottom: -67px;
      }
      .slick-next {
        left: 259px;
      }
      .slick-dots {
        bottom: -43px;
        left: 38px;
      }
    }
  }

  @media (max-width: 600px) {
    .video {
      max-height: 400px;
    }
    .section-main {
      padding: 100px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .section-main__inner {
      max-width: 350px;
      width: 100%;
    }
    .section-main__title2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 18px;
    }
    .section-main__text {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin-bottom: 52px;
    }
    .section-main__link {
      font-size: 20px;
      max-width: 350px;
      width: 100%;
    }
    .services {
      padding: 62px 0 16px;
    }
    .services__item-wrap {
      padding: 40px 0 56px;
    }
    .services__item {
      max-width: 300px;
      align-self: flex-start;
      &::before {
        width: 20px;
        height: 20px;
      }
      &-imgs {
        display: flex;
        flex-direction: column;
        &.active {
          height: auto;
          margin-top: 20px;
        }
        p {
          font-size: 14px;
          margin-top: 0;
          margin-bottom: 20px;
        }
        & > div {
          width: 100%;
        }
      }
    }
    .services__item-title2 {
      font-size: 26px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.52px;
      margin-bottom: 20px;
    }
    .services__item-text {
      font-size: 18px;
      line-height: 31px;
      letter-spacing: 0.36px;
    }
    .presentation {
      padding: 42px 0 62px;
      &__link {
        width: 100%;
      }
      &__info {
        flex-wrap: wrap;
      }
      &__descr {
        max-width: 390px;
      }
      &__title2 {
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 12px;
        margin-top: 0;
      }
      &__text {
        font-size: 18px;
        line-height: 31px;
      }
      &__slide {
        margin-top: 20px;
        width: 100%;
        .slick-list {
          height: auto;
          width: 100%;
        }
        .slick-arrow {
          bottom: -56px;
          width: 32px;
          height: 32px;
          border-radius: 32px;
        }
        .slick-dots {
          bottom: -35px;
        }
      }
    }
    .title2 {
      font-size: 28px;
      font-weight: 500;
    }

    .mission {
      margin-bottom: 64px;
      max-width: 351px;
      margin-right: auto;
      margin-left: auto;
      &__about {
        flex-direction: column;
        margin-bottom: 34px;
      }
      &__title2 {
        font-size: 28px;
        margin-bottom: 32px;
      }
      &__descr {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 0;
        margin-top: 0;
        letter-spacing: 0.48px;
      }
      &__items {
        row-gap: 14px;
        flex-wrap: wrap;
      }
      &__item-title2 {
        font-size: 24px;
        margin-bottom: 22px;
      }
      &__item-text {
        font-size: 18px;
        line-height: 33px;
      }
    }

    .prices {
      padding-top: 62px;
      &__title2 {
        font-size: 28px;
      }
      &__cards {
        gap: 20px;
      }
      &__card-wrap {
        width: 100%;
        max-width: 400px;
        padding: 36px 30px;
      }
      &__card-price {
        font-size: 40px;
      }
      &__card-date {
        font-size: 24px;
        margin-bottom: 46px;
        line-height: 36px;
      }
      &__card-descr {
        font-weight: 700;
      }
      &__card-link {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }
    .prices-container {
      padding: 0 20px;
    }

    .prices-cards {
    }
  }
`;
