import styled from "@emotion/styled";

export const ImageCropperWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  background: linear-gradient(
    0.07deg,
    rgba(3, 6, 13, 1) 35.24%,
    rgba(38, 55, 82, 1) 290.72%
  );
  border: 1px solid #51a7ca;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
  color: #fff;
  overflow-x: hidden;
  text-align: center;
  position: relative;
  width: 100%;
  padding: 20px 0;
  .close-button {
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    border: none;
    max-width: 34px;
    margin: 0;
    top: 15px;
    right: 10px;
  }
  .rmc-cropper-wrapper,
  .overlay {
    box-shadow: none;
    background: transparent;
  }
`;
