import styled from "@emotion/styled";

export const PageLayoutWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  &.menu-active {
    max-height: 100vh;
  }
  .container {
    max-width: 1247px;
    padding: 0 16px;
    margin: 0 auto;
  }
  .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 24px;
    padding-bottom: 20px;
    color: #000000;
    position: relative;
    text-align: center;
    margin: 0;
    &:after {
      width: 110px;
      height: 4px;
      background: #000000;
      border-radius: 6.5px;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .bg-gray {
    background: linear-gradient(
      174.55deg,
      rgba(175, 175, 175, 0.2) -55.98%,
      rgba(175, 175, 175, 0) 72.7%
    );
  }
  .bg-gray-2 {
    background: linear-gradient(
      180.54deg,
      #afafaf -40.17%,
      rgba(175, 175, 175, 0.1) 0%
    );
  }
  @media screen and (max-width: 1200px) {
    .title {
      font-size: 32px;
      padding-bottom: 20px;
      &:after {
        width: 80px;
        height: 3px;
      }
    }
  }
`;
