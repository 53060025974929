import cookies from "react-cookies";
import { toast } from "react-toastify";
import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  PayloadAction,
} from "@reduxjs/toolkit";

import { COOKIES, HTTP_STATUS, MESSAGES } from "../helpers/constants";
import { IErrorResponse } from "../types/Error";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) =>
  (next) =>
  (action: PayloadAction<Response & { data: IErrorResponse }>) => {
    if (isRejectedWithValue(action)) {
      let errorMessage = MESSAGES.ERROR_HAPPENED;

      const errorData = action.payload.data;
      const status = action.payload.status;

      if (errorData) {
        errorMessage = action.payload.data.message;
      }

      toast.error(errorMessage);

      switch (status) {
        case HTTP_STATUS.UNAUTHORIZED:
          cookies.remove(COOKIES.TOKEN, { path: "/" });
          return window.location.reload();
        default:
      }
    }

    return next(action);
  };
