import styled from "@emotion/styled";

export const PhoneConfirmationWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background: #1e2838;
  border: 2px solid #51a7ca;
  box-shadow: 0 0 12px rgba(90, 104, 119, 0.25);
  border-radius: 20px;
  padding: 47px 20px 46px;
  color: #fff;
  overflow: hidden;
  text-align: center;
  position: relative;
  .close-button {
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    border: none;
    top: 20px;
    right: 23px;
  }
  .change-number-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-transform: inherit;
    margin: 11px 0 33px;
  }
  .sms {
    & > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
    & > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #8f95a1;
      display: block;
      margin-bottom: 38px;
    }
  }
  .otp-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 43px;
    p {
      margin-top: 23px;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
    span {
      color: var(--accent-color);
    }
  }
`;
