import * as yup from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject, Maybe } from "yup/lib/types";

import { REGULAR_EXPRESSIONS, VALIDATION_ERRORS } from "./constants";
import { checkIIN } from "./funcs";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "iin",
  function pattern(name, message = VALIDATION_ERRORS.NOT_VALID_FIELD) {
    return this.test({
      message,
      test: function (iin) {
        return checkIIN(iin);
      },
    });
  }
);

// yup.addMethod<yup.StringSchema>(yup.string, 'password', function pattern(name, message = VALIDATION_ERRORS.NOT_VALID_PASSWORD) {
//   // return this.minLo
// })

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    iin(): RequiredStringSchema<TType, TContext>;
    // password(): RequiredStringSchema<TType, TContext>;
  }
}

export default yup;
