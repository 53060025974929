import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import AppFooter from "./Footer";
import AppHeader from "./Header";
import { AppLayoutWrapper } from "./styles";
const AppLayout = () => {
  return (
    <AppLayoutWrapper>
      <Box bgcolor="gray.100" height="100%">
        <AppHeader />
        <Outlet />
        <AppFooter />
      </Box>
    </AppLayoutWrapper>
  );
};

export default AppLayout;
