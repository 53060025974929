import styled from "@emotion/styled";

import mainBg from "../../../assets/images/page/main-bg.jpg";

export const MobileMenuWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${mainBg});
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: -10;
  transition: opacity 0.3s ease;
  overflow: hidden;
  &.active {
    z-index: 2;
    opacity: 1;
  }
  .close-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    svg {
      path {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
  .mb-menu {
  }
  menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style-type: none;
    gap: 20px;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
      margin-left: 20px;
      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        transition: color, opacity 0.3s ease;
        &:hover {
          color: var(--accent-color);
          opacity: 0.5;
        }
        &.active {
          color: var(--accent-color);
        }
      }
    }
  }
`;
