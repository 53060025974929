import styled from "@emotion/styled";

export const CustomDatePickerWrapper = styled.div`
  input {
    background: #253041;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 16px;
    &::-webkit-calendar-picker-indicator {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }
  }
`;
