import styled from "@emotion/styled";

export const PageFooterWrapper = styled.footer`
  box-sizing: border-box;
  padding-top: 51px;
  background-color: #131032;
  .footer {
    display: flex;
    justify-content: space-between;
    h2 {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: #badaff;
      margin: 0;
    }
    &-left {
      width: 50%;
      max-width: 232px;
      .contact-link {
        font-weight: 600;
        line-height: 16px;
        text-transform: lowercase;
        color: #badaff;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 20px 0 26px;
        font-size: 14px;
        &:hover {
          color: var(--accent-color);
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
          a {
            display: block;
            margin: 0;
            width: 55px;
            height: 53px;
            svg path {
              fill: #badaff;
              transition: all 0.3s ease;
            }
            &:hover svg path {
              fill: var(--accent-color);
            }
          }
        }
      }
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      width: 50%;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    margin-top: 28px;
    width: fit-content;
    align-items: flex-end;
    .form-container {
      display: flex;
      column-gap: 34px;
    }
    .form-left {
      display: flex;
      flex-direction: column;
      row-gap: 23px;
      &-input {
        width: 256px;
        height: 45px;
        background: #131032;
        border: 1px solid #515b7e;
        border-radius: 8px;
        padding-left: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #badaff;
        transition: all 0.3s ease;
        outline: none;
        &::placeholder {
          font-weight: 400;
          font-size: 18px;
          color: #badaff;
        }
      }
      &:focus {
        border-color: #badaff;
      }
    }
    .form-right-textarea {
      width: 350px;
      height: 182px;
      padding-left: 14px;
      padding-top: 11px;
      background: #131032;
      border: 1px solid #515b7e;
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #badaff;
      resize: none;
      transition: all 0.3s ease;
      outline: none;
      &::placeholder {
        font-weight: 400;
        font-size: 18px;
        color: #badaff;
      }
      &:focus {
        border-color: #badaff;
      }
    }
    .form-button {
      display: block;
      height: 56px;
      width: 350px;
      border-radius: 30px;
      border: 1px solid #badaff;
      background-color: transparent;
      color: #badaff;
    }
  }
  .privacy {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 34px 0;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #badaff;
      margin: 0 52px 0 0;
      text-transform: uppercase;
    }
    a {
      display: block;
      position: relative;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #badaff;
      text-decoration: none;
      transition: all 0.3s ease;
      &:before {
        content: "";
        position: absolute;
        display: block;
        height: 2px;
        width: 100%;
        bottom: -4px;
        background-color: #afafaf;
        transition: all 0.3s ease;
      }
      &:hover {
        color: var(--accent-color);
        &:before {
          background-color: var(--accent-color);
        }
      }
    }
  }
  .terms {
    margin-right: 28px;
  }
  .policy {
    margin-right: 28px;
  }

  @media screen and (max-width: 1600px) {
    .footer {
      max-width: 872px;
      margin: 0 auto;
      &-left {
        width: auto;
      }
      &-right {
        width: auto;
      }
      h2 {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .form {
      margin-top: 22px;
      .form-left-input {
        max-width: 240px;
        font-size: 12px;
        &::placeholder {
          font-size: 12px;
        }
      }
      .form-right-textarea {
        max-width: 260px;
        font-size: 12px;
        &::placeholder {
          font-size: 12px;
        }
      }
      .form-button {
        width: 260px;
        padding-top: 12px;
        padding-bottom: 12px;
        height: auto;
      }
    }
    .privacy {
      max-width: 872px;
      margin: 0 auto;
      p {
        font-size: 12px;
        line-height: 14px;
      }
      a {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
      &-left {
        max-width: 400px;
        width: 100%;
      }
      &-right {
        margin-top: 30px;
        width: max-content;
      }
    }
    .privacy {
      text-align: center;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0;
      }
    }
    .terms {
      margin: 20px 0;
    }
    .policy {
      margin: 0 0 20px 0;
    }
    .form {
      align-items: center;
    }
    .form-container {
      flex-direction: column;
      align-items: center;
    }
    .form-right-textarea {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    .form {
      .form-right-textarea {
        width: 258px;
      }
      .form-button {
        width: 258px;
      }
    }
    .footer {
      &-right {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;
