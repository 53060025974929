export const COOKIES = {
  TOKEN: "token",
  REDIRECT_URL: "redirectUrl",
};

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const API_TAGS = {
  USER: "user",
  CASE: "case",
  TRG: "trg",
  CBCT: "cbct",
  PAYMENT: "payment",
  PHOTO_PROTOCOL: "photo-protocol",
  PHOTO_PROTOCOL_IMAGE_CROPPED: "photo-protocol-image-cropped",
  PHOTO_PROTOCOL_IMAGE_EDITED: "photo-protocol-image-edited",
  PHOTO_PROTOCOL_IMAGE_STOCK: "photo-protocol-image-stock",
  QUESTIONNAIRE: "questionnaire",
  PHOTO_PROTOCOL_PROPERTY: "photo-protocol-property",
};

export const VALIDATION_ERRORS = {
  NOT_VALID_FIELD: "Введите валидное значение",
  NOT_VALID_PASSWORD:
    "Пароль должен содержать латинские буквы разного регистра, цифры, а так же специальные символы (@, #, $ и тд…)",
  REQUIRED_FIELD: "Обязательно заполению",
  SPACES: "Поле не должно содержать лишних пробелов",
  PASSWORD_NOT_MATCHES: "Пароль не совпадает",
  SUCH_ACCOUNT_WITH_FIELD_EXISTS: (fieldName: string) =>
    `Пользователь с таким "${fieldName}" существует!`,
};

export const MESSAGES = {
  ERROR_HAPPENED: "Произошла ошибка.",
  USER_SUCCESS_REGISTERED: "Пользователь успешно зарегистрирован",
};

export const REGULAR_EXPRESSIONS = {
  // Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  // Cyrillic forbidden
  WITHOUT_CYRILLIC: /^(?!.*[А-ЯЁа-яё])/,
  // Kazakhstan number
  KZ_PHONE_NUMBER: /^\+?77(\d{9})$/,
};

export const DATE_FORMAT = "dd.MM.yyyy";

export enum LoadingStatus {
  LOADING = "loading",
  LOADED = "loaded",
  FAILED = "failed",
}

export const LANGUAGES = [
  {
    code: "ru",
    title: "ru",
    name: "Русский",
  },
  {
    code: "en",
    title: "en",
    name: "English",
  },
  {
    code: "kz",
    title: "kz",
    name: "Қазақша",
  },
  {
    code: "pt",
    title: "pt",
    name: "Português",
  },
  {
    code: "ar",
    title: "ar",
    name: "أر",
  },
];
