import React from "react";
import { Box, Typography } from "@mui/material";

import emptyBoxImg from "../../assets/images/empty-box.png";

const EmptyBox: React.FC<{ title?: string }> = ({
  title = "Пациент не найден",
}) => {
  return (
    <Box
      sx={{
        padding: 6,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img alt="Not found" src={emptyBoxImg} />
      <Typography variant="bold1">{title}</Typography>
    </Box>
  );
};

export default EmptyBox;
