import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { useAppSelector } from "../../app/hooks";
import aiImg from "../../assets/images/page/ai-img.png";
import xrayImg from "../../assets/images/page/x-ray-img.png";
import slide1 from "../../assets/images/page-carousel/1.png";
import slide2 from "../../assets/images/page-carousel/2.png";
import slide3 from "../../assets/images/page-carousel/3.png";
import slide4 from "../../assets/images/page-carousel/4.png";
import slide5 from "../../assets/images/page-carousel/5.png";
import slide6 from "../../assets/images/page-carousel/6.png";
import slide7 from "../../assets/images/page-carousel/7.png";
import PATH from "../../helpers/PATH";

import { CephalometricAnalysisWrapper } from "./styles";

function SampleNextArrow(props: React.HTMLProps<HTMLButtonElement>) {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <svg fill="none" viewBox="0 0 66 66">
        <path
          d="M45.6109 34.0608C46.1966 33.475 46.1966 32.5252 45.6109 31.9395L36.0649 22.3935C35.4791 21.8077 34.5294 21.8077 33.9436 22.3935C33.3578 22.9793 33.3578 23.9291 33.9436 24.5148L42.4289 33.0001L33.9436 41.4854C33.3578 42.0712 33.3578 43.0209 33.9436 43.6067C34.5294 44.1925 35.4791 44.1925 36.0649 43.6067L45.6109 34.0608ZM21.4502 34.5001H44.5502V31.5001H21.4502V34.5001Z"
          fill="white"
        />
      </svg>
    </button>
  );
}

function SamplePrevArrow(props: React.HTMLProps<HTMLButtonElement>) {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <svg fill="none" viewBox="0 0 66 66">
        <path
          d="M20.3891 34.0608C19.8034 33.475 19.8034 32.5252 20.3891 31.9395L29.9351 22.3935C30.5209 21.8077 31.4706 21.8077 32.0564 22.3935C32.6422 22.9793 32.6422 23.9291 32.0564 24.5148L23.5711 33.0001L32.0564 41.4854C32.6422 42.0712 32.6422 43.0209 32.0564 43.6067C31.4706 44.1925 30.5209 44.1925 29.9351 43.6067L20.3891 34.0608ZM44.5498 34.5001H21.4498V31.5001H44.5498V34.5001Z"
          fill="white"
        />
      </svg>
    </button>
  );
}

const CephalometricAnalysis = () => {
  const [activeServiceTab, setActiveServiceTab] = useState(0);
  const token = useAppSelector((state) => state.userSlice.token);
  const { t } = useTranslation("page");

  const slickSettings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    dots: true,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow className="button" />,
    prevArrow: <SamplePrevArrow className="button" />,
  };

  return (
    <CephalometricAnalysisWrapper>
      <div className="main">
        <div className="header-main">
          <section className="section-main">
            <div className="container">
              <div className="section-main__inner">
                <h1 className="section-main__title2">
                  <Trans i18nKey="page:cephalometric_title">
                    <p>
                      <Trans i18nKey="page:ceph_title">
                        Цефалометрический <br /> онлайн анализ br за 1 минуту
                      </Trans>
                    </p>
                  </Trans>
                </h1>
                <p className="section-main__text">{t("ceph_text")}</p>
                <Link
                  className="section-main__link link-all"
                  to={token ? PATH.APP_TARIFFS : PATH.APP_SIGNUP}
                >
                  {t("try", { context: "free" })}
                </Link>
              </div>
            </div>
          </section>
        </div>
        <div className="works">
          <div className="container">
            <h2 className="works__titl2 title2">{t("works_title")}</h2>
            <ul>
              <li className="li">
                <div>
                  <h6>{t("works_title1")}</h6>
                  <p>{t("works_text1")}</p>
                </div>
                <img alt="aidentis" src={xrayImg} />
              </li>
              <li className="li">
                <img alt="aidentis" src={xrayImg} />
                <div>
                  <h6>{t("works_title2")}</h6>
                  <p>{t("works_text2")}</p>
                </div>
              </li>
              <li className="li">
                <div>
                  <h6>{t("works_title3")}</h6>
                  <p>{t("works_text3")}</p>
                </div>
                <img alt="aidentis" src={aiImg} />
              </li>
              <li className="li">
                <div className="presentation__slide">
                  <Slider {...slickSettings}>
                    <li>
                      <img alt="slide" src={slide1} />
                    </li>
                    <li>
                      <img alt="slide" src={slide2} />
                    </li>
                    <li>
                      <img alt="slide" src={slide3} />
                    </li>
                    <li>
                      <img alt="slide" src={slide4} />
                    </li>
                    <li>
                      <img alt="slide" src={slide5} />
                    </li>
                    <li>
                      <img alt="slide" src={slide6} />
                    </li>
                    <li>
                      <img alt="slide" src={slide7} />
                    </li>
                  </Slider>
                </div>
                <div>
                  <h6>{t("works_title4")}</h6>
                  <p>{t("works_text4")}</p>
                  <a href="#">{t("works_download")}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </CephalometricAnalysisWrapper>
  );
};

export default CephalometricAnalysis;
