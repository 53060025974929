import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";

import video from "../../assets/AIDENTIS_no_logo.mp4";

import { AuthLayoutWrapper } from "./styles";

const AuthLayout = () => {
  return (
    <AuthLayoutWrapper>
      <video
        style={{
          position: "fixed",
          top: "75px",
          left: "0",
          right: "0",
          bottom: "0",
          minWidth: "100%",
          minHeight: "94%",
          opacity: 0.5,
        }}
        autoPlay
        loop
        muted
      >
        <source src={video} type="video/mp4" />
      </video>
      <Container>
        <Box sx={{ position: "relative", zIndex: 2 }}>
          <Outlet />
        </Box>
      </Container>
    </AuthLayoutWrapper>
  );
};

export default AuthLayout;
