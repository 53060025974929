import styled from "@emotion/styled";

export const OtpInputWrapper = styled.div`
  & > div {
    & > div {
      input {
        background: #253041;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
        width: 32px;
        height: 51px;
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        outline: var(--accent-color);
        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #fff;
        }
      }
    }
  }
`;
