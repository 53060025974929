import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import mastercardIcon from "../../../assets/svg/mastercard.svg";
import visaIcon from "../../../assets/svg/visa.svg";
import PATH from "../../../helpers/PATH";
import { ECountryCode } from "../../../types/Common";
import { EPrivacyType } from "../../../types/Privacy";

import { AppFooterWrapper } from "./styles";

const AppFooter = () => {
  const { t } = useTranslation();

  return (
    <AppFooterWrapper>
      <div className="container">
        <div className="privacy">
          <p>2022 ALL RIGHTS RESERVED AIDENTIS</p>
          <div
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            <Link
              className="terms"
              to={PATH.generate(
                PATH.APP_PRIVACY_RULE,
                ECountryCode.GLOBAL,
                EPrivacyType.TERMS
              )}
            >
              {t("common:terms")}
            </Link>
            <Link
              className="policy"
              to={PATH.generate(
                PATH.APP_PRIVACY_RULE,
                ECountryCode.GLOBAL,
                EPrivacyType.PRIVACY_POLICY
              )}
            >
              {t("common:privacy-policy")}
            </Link>
          </div>
          <div style={{ marginRight: "auto" }}>
            <img alt="Visa Aidentis" src={visaIcon} style={{ width: "54px" }} />
            <img
              alt="Mastercard Aidentis"
              src={mastercardIcon}
              style={{ width: "54px" }}
            />
          </div>
        </div>
      </div>
    </AppFooterWrapper>
  );
};

export default AppFooter;
