import DeletePatientConfirmation from "./DeletePatientConfirmation";
import ImageCropper from "./ImageCropper";
import NewPatient from "./NewPatient";
import PhoneConfirmation from "./PhoneConfirmation";

const Modals = () => {
  return (
    <>
      <PhoneConfirmation />
      <DeletePatientConfirmation />
      <NewPatient />
      <ImageCropper />
    </>
  );
};

export default Modals;
