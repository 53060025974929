import styled from "@emotion/styled";

export const DeletePatientConfirmationWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  background: linear-gradient(
    0deg,
    rgba(3, 6, 13, 1) -100%,
    rgba(38, 55, 82, 1) 100%
  );
  filter: drop-shadow(0px 4px 4px black);
  border: 2px solid #51a7ca;
  box-shadow: 0 0 12px rgba(90, 104, 119, 0.25);
  border-radius: 20px;
  padding: 32px 0 0;
  color: #fff;
  overflow: hidden;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 500px;
  .close-button {
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    border: none;
    top: 15px;
    right: 10px;
  }
`;
