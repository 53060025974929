import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { togglePhoneConfirmationModal } from "../../app/modal/slice";
import { fillInSignUpForm } from "../../app/user/slice";
import {
  REGULAR_EXPRESSIONS,
  VALIDATION_ERRORS,
} from "../../helpers/constants";
import yup from "../../helpers/yup";

export const useSignUpForm = () => {
  const dispatch = useAppDispatch();
  const signUpForm = useAppSelector((state) => state.userSlice.signUpForm);
  const [isAgree, setIsAgree] = useState(false);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: signUpForm,
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(t("form:validation", { context: "notValidField" }) || "")
        .required(t("form:validation", { context: "requiredField" }) || "")
        .trim(),
      // confirmationCode: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      lastName: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      firstName: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      clinicName: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      password: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || "")
        // .matches(
        //   REGULAR_EXPRESSIONS.PASSWORD,
        //   VALIDATION_ERRORS.NOT_VALID_PASSWORD
        // )
        .trim(),
      phoneNumber: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || "")
        .trim(),
      matchingPassword: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || "")
        .oneOf(
          [yup.ref("password"), null],
          t("form:validation", { context: "passwordNotMatches" }) || ""
        ),
    }),
    onSubmit: async (values) => {
      if (!isAgree) {
        return toast.warn(
          t("form:message", { context: "notConfirmAgreements" })
        );
      }
      dispatch(fillInSignUpForm(values));
      dispatch(togglePhoneConfirmationModal(true));
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [i18n.language]);

  return {
    formik,
    isAgree,
    handleIsAgree: () => setIsAgree(!isAgree),
    t,
    Trans,
  };
};
