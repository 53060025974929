import React from "react";

import { CustomDatePickerWrapper } from "./styles";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

const DatePickerTimePicker: React.FC<IProps> = ({ value, label, onChange }) => {
  const onChangeDateTimeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <CustomDatePickerWrapper>
      {label ? <label htmlFor="#datetime">{label}</label> : null}
      <input
        id="datetime"
        type="datetime-local"
        value={value}
        onChange={onChangeDateTimeValue}
      />
    </CustomDatePickerWrapper>
  );
};

export default DatePickerTimePicker;
