import styled from "@emotion/styled";

export const AppFooterWrapper = styled.footer`
  box-sizing: border-box;
  padding: 16px 0 46px;
  background: #000;
  .privacy {
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #badaff;
      margin: 0 52px 0 0;
      text-transform: uppercase;
    }
    a {
      display: block;
      position: relative;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #badaff;
      text-decoration: none;
      transition: all 0.3s ease;
      &:before {
        content: "";
        position: absolute;
        display: block;
        height: 2px;
        width: 100%;
        bottom: -4px;
        background-color: #afafaf;
        transition: all 0.3s ease;
      }
      &:hover {
        color: var(--accent-color);
        &:before {
          background-color: var(--accent-color);
        }
      }
    }
  }

  @media screen and (max-width: 1600px) {
  }

  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 600px) {
  }
`;
