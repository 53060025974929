import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem, Zoom } from "@mui/material";

import LangSwitcher from "../../../components/LangSwitcher";
import PATH from "../../../helpers/PATH";
import Icon from "../../../icons";

import { MobileMenuWrapper } from "./styles";

interface IProps {
  mode?: "dark" | "light";
  isMenuActive: boolean;
  setIsMenuActive: (v: boolean) => void;
}

const MobileMenu: React.FC<IProps> = ({
  mode = "dark",
  isMenuActive,
  setIsMenuActive,
}) => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const [productsAnchorEl, setProductsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const productsMenuOpen = Boolean(productsAnchorEl);

  useEffect(() => {
    let anchorElement: HTMLDivElement | null = null;
    if (hash) {
      anchorElement = document.querySelector(hash);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }

    return () => {
      if (anchorElement) anchorElement.remove();
    };
  }, [hash]);

  const handleProductsMenuClose = () => {
    setProductsAnchorEl(null);
  };

  const handleProductsMenuItemClick = () => {
    setIsMenuActive(false);
    handleProductsMenuClose();
    navigate(PATH.CEPHALOMETRIC_ANALYSIS);
  };

  const handleProductsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setProductsAnchorEl(event.currentTarget);
  };

  return (
    <MobileMenuWrapper className={`${isMenuActive ? "active" : ""}`}>
      <button className="close-btn" onClick={() => setIsMenuActive(false)}>
        <Icon icon="close" />
      </button>
      <div className={`menu-mb ${isMenuActive ? "active" : ""}`}>
        <div>
          <menu>
            <li>
              {/*<NavLink to={PATH.CEPHALOMETRIC_ANALYSIS}>*/}
              {/*  {t("product_other")}*/}
              {/*</NavLink>*/}
              <a onClick={handleProductsMenuClick}>{t("product_other")}</a>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive && hash === "#price" ? "active" : undefined
                }
                to={{ pathname: PATH.HOME, hash: "price" }}
                onClick={() => setIsMenuActive(false)}
              >
                {t("price")}
              </NavLink>
            </li>
            <li>
              <Link to={PATH.APP_BOOK_A_DEMO}>{t("bookDemo")}</Link>
            </li>
            <li>
              <Link to={PATH.APP_SIGNUP} onClick={() => setIsMenuActive(false)}>
                {t("create", { context: "account" })}
              </Link>
            </li>
            <li>
              <LangSwitcher mode="light" />
            </li>
            {/*<li>*/}
            {/*  <Link*/}
            {/*    className="signIn-button"*/}
            {/*    to={token ? PATH.APP_CASES : PATH.APP_SIGNIN}*/}
            {/*  >*/}
            {/*    <svg fill="none" height="25" viewBox="0 0 25 25" width="25">*/}
            {/*      <circle cx="12.5" cy="12.5" fill="#80DBD2" r="12.5" />*/}
            {/*      <g clipPath="url(#clip0_446_95)">*/}
            {/*        <path*/}
            {/*          d="M12.5093 25C9.82147 24.9651 7.37996 24.1963 5.18977 22.6352C5.00025 22.5 4.95919 22.393 5.03769 22.1684C6.25216 18.6926 9.4848 16.5558 13.1535 16.8156C16.2102 17.0319 19.0202 19.2431 19.9653 22.1631C20.0364 22.3828 20.0043 22.4958 19.8118 22.6332C17.6225 24.1965 15.1807 24.9636 12.5093 25Z"*/}
            {/*          fill="white"*/}
            {/*        />*/}
            {/*        <path*/}
            {/*          d="M16.2179 10.7384C16.2168 12.7761 14.5223 14.4788 12.4974 14.4767C10.4716 14.4746 8.78026 12.7704 8.78211 10.7333C8.78403 8.69237 10.4716 6.99884 12.5025 7C14.533 7.00116 16.2189 8.69763 16.2179 10.7384Z"*/}
            {/*          fill="white"*/}
            {/*        />*/}
            {/*      </g>*/}
            {/*      <defs>*/}
            {/*        <clipPath id="clip0_446_95">*/}
            {/*          <rect*/}
            {/*            fill="white"*/}
            {/*            height="18"*/}
            {/*            transform="translate(5 7)"*/}
            {/*            width="15"*/}
            {/*          />*/}
            {/*        </clipPath>*/}
            {/*      </defs>*/}
            {/*    </svg>*/}
            {/*    <span>{t("signIn")}</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </menu>
        </div>
      </div>
      <Menu
        anchorEl={productsAnchorEl}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        open={productsMenuOpen}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: "50%",
              width: 10,
              height: 10,
              bgcolor: "rgb(255,255,255)",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: mode === "dark" ? "grey.200" : "#253041",
            },
            backgroundColor: mode === "light" ? "rgba(20, 29, 44, 1)" : "#fff",
            color: mode === "light" ? "#fff" : "#000",
            "& .MuiMenuItem-root": {
              whiteSpace: "break-spaces",
            },
          },
        }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Zoom}
        onClose={handleProductsMenuClose}
      >
        <MenuItem onClick={handleProductsMenuItemClick}>
          {t("page:ourSolutions", { context: "title1" }) || ""}
        </MenuItem>
        <MenuItem onClick={handleProductsMenuItemClick}>
          {t("page:ourSolutions", { context: "title2" }) || ""}
        </MenuItem>
        <MenuItem onClick={handleProductsMenuItemClick}>
          {t("page:ourSolutions", { context: "title3" }) || ""}
        </MenuItem>
      </Menu>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
