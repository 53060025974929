import { API_TAGS } from "../../helpers/constants";
import { convertBase64ToBlob } from "../../helpers/funcs";
import { IImg } from "../../types/Case";
import {
  IGetTRGSAnalysisTypesAllResponseProps,
  IGetTRGSMeasurementsAllRequestProps,
  IGetTRGSMeasurementsAllResponseProps,
  IGetTRGSPointsAIResponseProps,
  IGetTRGSPointsResponseProps,
  IPostCasesTRGSReportDataResponse,
  IPostCasesTRGSReportRequest,
  IPostUploadTRGImageRequestProps,
  IPutTRGSCalibrateRequestProps,
  ITRGMask,
  ITrgsPoint,
} from "../../types/TRG";
import { api } from "../api";

export const trgApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTRGSImage: builder.query<IImg, number | string>({
      query: (caseId) => ({ url: `cases/trgs/image/${caseId}` }),
      transformResponse: async (response: IImg): Promise<IImg> => {
        const splittedName = response.name.split(".");
        const blob = convertBase64ToBlob(
          `data:image/${splittedName[splittedName.length - 1]};base64,${
            response.image
          }`
        );
        const url = URL.createObjectURL(blob);
        return {
          ...response,
          image: url,
          size: blob.size,
        };
      },
      providesTags: () => [API_TAGS.TRG],
    }),
    postUploadTRGSImage: builder.mutation<
      unknown,
      { caseId: number | string } & IPostUploadTRGImageRequestProps
    >({
      query: ({ caseId, file }) => ({
        url: `cases/trgs/image/${caseId}`,
        method: "POST",
        body: file,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.TRG, API_TAGS.CASE],
    }),
    postReUploadTRGSImage: builder.mutation<
      unknown,
      { caseId: number | string } & IPostUploadTRGImageRequestProps
    >({
      query: ({ caseId, file }) => ({
        url: `cases/trgs/image/${caseId}/reupload`,
        method: "POST",
        body: file,
      }),
      extraOptions: {},
      invalidatesTags: [],
    }),
    putTRGSCalibrate: builder.mutation<
      unknown,
      { caseId: number | string } & IPutTRGSCalibrateRequestProps
    >({
      query: ({ caseId, ...credentials }) => ({
        url: `cases/trgs/calibrate/${caseId}`,
        method: "PUT",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.TRG, API_TAGS.CASE],
    }),
    // -------------------------------------------------------------------------------------------
    getCasesTRGSPointsAI: builder.query<
      IGetTRGSPointsAIResponseProps,
      number | string
    >({
      query: (caseId) => ({ url: `cases/trgs/points/ai/${caseId}` }),
      providesTags: () => [API_TAGS.TRG],
    }),
    getCasesTRGSPoints: builder.query<
      IGetTRGSPointsResponseProps,
      number | string
    >({
      query: (caseId) => ({ url: `cases/trgs/points/${caseId}` }),
      providesTags: () => [API_TAGS.TRG],
    }),
    getCasesTRGSDelineationAI: builder.query<ITRGMask, number | string>({
      query: (caseId) => ({ url: `cases/trgs/delineation/ai/${caseId}` }),
      transformResponse: async (response: ITRGMask): Promise<ITRGMask> => {
        // const splittedName = response.name.split(".");
        return {
          ...response,
          mask: `data:image;base64,${response.mask}`,
        };
      },
      providesTags: () => [API_TAGS.TRG],
    }),
    getCasesTRGSDelineation: builder.query<ITRGMask, number | string>({
      query: (caseId) => ({ url: `cases/trgs/delineation/${caseId}` }),
      transformResponse: async (response: ITRGMask): Promise<ITRGMask> => {
        // const splittedName = response.name.split(".");
        if (response.mask) {
          return {
            ...response,
            mask: `data:image;base64,${response.mask}`,
          };
        } else {
          return {
            ...response,
            mask: `data:image;base64,${response.image}`,
          };
        }
      },
      providesTags: () => [API_TAGS.TRG],
    }),
    // -------------------------------------------------------------------------------------------
    getTRGSPointsAll: builder.query<{ content: ITrgsPoint[] }, undefined>({
      query: () => ({ url: `/trgs/points/all` }),
      providesTags: () => [API_TAGS.TRG],
    }),
    getTRGSPoints: builder.query<ITrgsPoint, string | number>({
      query: (id) => ({ url: `/trgs/points/${id}` }),
      providesTags: () => [API_TAGS.TRG],
    }),
    getTRGSMeasurementsAll: builder.query<
      IGetTRGSMeasurementsAllResponseProps,
      IGetTRGSMeasurementsAllRequestProps
    >({
      query: (params) => ({
        url: `/trgs/measurements/all`,
        params: params,
      }),
      providesTags: () => [API_TAGS.TRG],
    }),
    getTRGSPointsImage: builder.query<IImg, string | number>({
      query: (id) => ({ url: `/trgs/points/image/${id}` }),
      providesTags: () => [API_TAGS.TRG],
      transformResponse: async (response: IImg): Promise<IImg> => {
        const splittedName = response.name.split(".");
        return {
          ...response,
          image: `data:image/${splittedName[splittedName.length - 1]};base64,${
            response.image
          }`,
        };
      },
    }),
    postCasesTRGSPoints: builder.mutation<
      unknown,
      { caseId: number | string; data: FormData }
    >({
      query: ({ caseId, data }) => ({
        url: `cases/trgs/points/${caseId}`,
        method: "POST",
        body: data,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.TRG, API_TAGS.CASE],
    }),
    postCasesTRGSPointsNotReady: builder.mutation<
      unknown,
      { caseId: number | string; data: FormData }
    >({
      query: ({ caseId, data }) => ({
        url: `cases/trgs/points/${caseId}?ready=false`,
        method: "POST",
        body: data,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.TRG, API_TAGS.CASE],
    }),
    getTRGSAnalysisTypes: builder.query({
      query: () => ({ url: `/trgs/analysis-types/all` }),
      providesTags: () => [API_TAGS.TRG],
      transformResponse: async (
        response: IGetTRGSAnalysisTypesAllResponseProps
      ): Promise<{ value: number; label: string }[]> => {
        return response.content.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
      },
    }),
    postCasesTRGSReport: builder.mutation<
      IImg,
      { body: IPostCasesTRGSReportRequest; caseId: string }
    >({
      query: ({ caseId, body }) => ({
        url: `cases/trgs/report/${caseId}`,
        method: "POST",
        body,
      }),
      transformResponse: async (response: IImg): Promise<IImg> => {
        const blob = convertBase64ToBlob(
          `data:application/pdf;base64,${response.image}`
        );
        const url = URL.createObjectURL(blob);
        return {
          ...response,
          image: url,
        };
      },
      extraOptions: {},
      invalidatesTags: [],
    }),
    postCasesTRGSReportData: builder.mutation<
      IPostCasesTRGSReportDataResponse,
      { body: IPostCasesTRGSReportRequest; caseId: string }
    >({
      query: ({ caseId, body }) => ({
        url: `cases/trgs/report/data/${caseId}`,
        method: "POST",
        body,
      }),
      transformResponse: async (
        response: IPostCasesTRGSReportDataResponse
      ): Promise<IPostCasesTRGSReportDataResponse> => {
        const blob = convertBase64ToBlob(
          `data:application/image;base64,${response.image}`
        );
        const url = URL.createObjectURL(blob);
        const delineationBlob = convertBase64ToBlob(
          `data:application/image;base64,${response.image}`
        );
        const delineationUrl = URL.createObjectURL(delineationBlob);
        return {
          ...response,
          image: url,
          delineation: delineationUrl,
        };
      },
      extraOptions: {},
      invalidatesTags: [],
    }),
  }),
});

export const {
  usePostCasesTRGSPointsMutation,
  usePostCasesTRGSPointsNotReadyMutation,
  usePostUploadTRGSImageMutation,
  usePostReUploadTRGSImageMutation,
  useGetTRGSImageQuery,
  usePutTRGSCalibrateMutation,

  useLazyGetCasesTRGSPointsAIQuery,
  useLazyGetCasesTRGSDelineationAIQuery,
  useGetCasesTRGSDelineationQuery,
  useLazyGetCasesTRGSDelineationQuery,
  useGetCasesTRGSPointsQuery,
  useLazyGetCasesTRGSPointsQuery,

  useGetTRGSPointsQuery,
  useGetTRGSPointsAllQuery,
  useGetTRGSPointsImageQuery,
  useLazyGetTRGSMeasurementsAllQuery,
  useGetTRGSAnalysisTypesQuery,
  useGetTRGSMeasurementsAllQuery,
  usePostCasesTRGSReportMutation,
  usePostCasesTRGSReportDataMutation,
} = trgApi;
