import { createSlice } from "@reduxjs/toolkit";

import { API_TAGS } from "../../helpers/constants";

const initialState = {};

const paymentSlice = createSlice({
  name: API_TAGS.PAYMENT,
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const {} = paymentSlice.actions;
export default paymentSlice.reducer;
