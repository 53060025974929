import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(196, 196, 196, 0.2);",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(196, 196, 196, 0.2);",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    color: "#FFFFFF",
    maxWidth: 281,
  },
}));

export default CustomTooltip;
