import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { formatISO } from "date-fns";
import { useFormik } from "formik";

import { usePostDemoBookMutation } from "../../app/user/api";
import yup from "../../helpers/yup";

export const useSignUpForm = () => {
  const [isAgree, setIsAgree] = useState(false);
  const { t, i18n } = useTranslation();
  const [bookDemo, { isLoading, isSuccess }] = usePostDemoBookMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
      country: "",
      bookingDate: "",
      name: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(t("form:validation", { context: "notValidField" }) || "")
        .required(t("form:validation", { context: "requiredField" }) || "")
        .trim(),
      phoneNumber: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || "")
        .trim(),
      country: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      bookingDate: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
      name: yup
        .string()
        .required(t("form:validation", { context: "requiredField" }) || ""),
    }),
    onSubmit: async ({ bookingDate, ...values }) => {
      const body: {
        email: string;
        phoneNumber: string;
        country: string;
        bookingDate?: string;
        name: string;
      } = values;
      if (bookingDate) {
        const date = formatISO(new Date(bookingDate));
        body.bookingDate = date;
      }
      bookDemo(body);
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [i18n.language]);

  return {
    formik,
    isAgree,
    handleIsAgree: () => setIsAgree(!isAgree),
    t,
    Trans,
    isLoading,
    isSuccess,
  };
};
