import React, { useState } from "react";
import { BiCheck } from "react-icons/bi";

import { AppCheckboxWrapper } from "./styles";

interface IProps {
  label?: string | React.ReactNode;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

const AppCheckbox: React.FC<IProps> = ({
  label,
  checked = false,
  onChange,
}) => {
  return (
    <AppCheckboxWrapper onClick={(e) => e.stopPropagation()}>
      <input
        checked={checked}
        type="checkbox"
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      <div>
        <BiCheck />
      </div>
      {typeof label === "string" && <span className="label">{label}</span>}
      {typeof label === "object" && label}
    </AppCheckboxWrapper>
  );
};

export default AppCheckbox;
