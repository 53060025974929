import React from "react";

import icons from "./icons";

const Icon: React.FC<{ icon: string }> = ({ icon = "" }) => {
  if (!icon || !icons[icon]) {
    console.error(`Icon "${icon}" doesn't exist!`);
    return null;
  }
  return icons[icon].icon;
};

export default Icon;
