import type {} from "@mui/lab/themeAugmentation";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import palette from "./palette";
import typography from "./typography";

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "simple" },
          style: {
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "20px",
            color: "#fff",
            background: "var(--accent-color)",
            borderRadius: "31.5px",
            textTransform: "none",
            padding: 0,
            transition: "all 0.3s ease",
            maxWidth: "max-content",
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "&:hover": {
              background: "var(--accent-color)",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "app-button" },
          style: {
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#000000",
            background: "var(--accent-color)",
            borderRadius: "10px",
            textTransform: "none",
            width: "100%",
            padding: 16,
            transition: "all 0.3s ease",
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "& .MuiCircularProgress-root": {
              width: "22px !important",
              height: "22px !important",
              color: "#fff",
            },
            "&:hover": {
              background: "var(--accent-color)",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "app-button-md" },
          style: {
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            background: "var(--accent-color)",
            borderRadius: "10px",
            textTransform: "none",
            width: "100%",
            maxWidth: 401,
            padding: 16,
            transition: "all 0.3s ease",
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "& .MuiCircularProgress-root": {
              width: "22px !important",
              height: "22px !important",
              color: "#fff",
            },
            "&:hover": {
              background: "var(--accent-color)",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "app-button-sm" },
          style: {
            maxWidth: 217,
            height: 54,
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "17px",
            color: "#000000",
            background: "var(--accent-color)",
            borderRadius: "10px",
            textTransform: "none",
            width: "100%",
            padding: 12,
            transition: "all 0.3s ease",
            "&.Mui-disabled": {
              opacity: 0.8,
              color: "#000000",
              cursor: "not-allowed",
            },
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "& .MuiCircularProgress-root": {
              width: "22px !important",
              height: "22px !important",
              color: "#fff",
            },
            "&:hover": {
              background: "var(--accent-color)",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "app-button-sm2" },
          style: {
            height: "100%",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            background: "var(--accent-color)",
            borderRadius: "10px",
            textTransform: "none",
            width: "100%",
            padding: 12,
            transition: "all 0.3s ease",
            border: "2px solid transparent",
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "& .MuiCircularProgress-root": {
              width: "22px !important",
              height: "22px !important",
              color: "#fff",
            },
            "&:hover": {
              background: "var(--accent-color)",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "app-btn-gray-sm" },
          style: {
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#fff",
            background: "#334157",
            borderRadius: "10px",
            textTransform: "none",
            width: "100%",
            padding: 12,
            transition: "all 0.3s ease",
            border: "2px solid transparent",
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "& .MuiCircularProgress-root": {
              width: "22px !important",
              height: "22px !important",
              color: "#fff",
            },
            "&.Mui-disabled": {
              color: "rgba(255,255,255, .6)",
            },
            "&:hover": {
              borderColor: "#51A7CA",
              background: "#334157",
            },
          },
        },
        {
          props: { variant: "app-btn-gray2-xs" },
          style: {
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "16px",
            color: "#fff",
            background: "#546176",
            borderRadius: "6px",
            textTransform: "none",
            width: "100%",
            padding: "8px 19px",
            transition: "all 0.3s ease",
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "& .MuiCircularProgress-root": {
              width: "22px !important",
              height: "22px !important",
              color: "#fff",
            },
            "&.Mui-disabled": {
              color: "rgba(255,255,255, .6)",
            },
            "&:hover": {
              background: "#192232",
            },
          },
        },

        {
          props: { variant: "app-btn-red-sm" },
          style: {
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#fff",
            background:
              "linear-gradient(180.61deg, rgba(20, 29, 44, 0) -79.63%, #7B2746 112.62%)",
            borderRadius: "6px",
            textTransform: "none",
            width: "100%",
            padding: 12,
            transition: "all 0.3s ease",
            border: "2px solid #ED5F68",
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "& .MuiCircularProgress-root": {
              width: "22px !important",
              height: "22px !important",
              color: "#fff",
            },
            "&.Mui-disabled": {
              color: "rgba(255,255,255, .6)",
            },
            "&:hover": {
              opacity: 0.8,
            },
          },
        },
        {
          props: { variant: "app-btn-green-sm" },
          style: {
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#fff",
            background:
              "linear-gradient(90.81deg, #2D494F 21.94%, rgba(45, 73, 79, 0) 139.01%)",
            borderRadius: "6px",
            textTransform: "none",
            width: "100%",
            padding: 12,
            transition: "all 0.3s ease",
            border: "2px solid #3BDFCF",
            boxShadow: "0px 0px 5px #3BDFCF",
            "& > a": {
              textDecoration: "none",
              color: "#fff",
              padding: "13px 33px",
            },
            "& .MuiCircularProgress-root": {
              width: "22px !important",
              height: "22px !important",
              color: "#fff",
            },
            "&.Mui-disabled": {
              color: "rgba(255,255,255, .6)",
            },
            "&:hover": {
              opacity: 0.8,
            },
          },
        },
      ],
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          "&.MuiContainer-maxWidthLg": {
            maxWidth: 1468,
          },
        },
      },
    },
  },
  palette,
  typography,
});

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    simple: true;
    "app-button": true;
    "app-button-md": true;
    "app-button-sm": true;
    "app-btn-gray-sm": true;
    "app-btn-gray2-xs": true;
    "app-btn-red-sm": true;
    "app-btn-green-sm": true;
    "app-button-sm2": true;
  }
}

export default responsiveFontSizes(theme);
