import * as React from "react";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";

import { ISelection } from "../../types";

import { AppSelectWrapper } from "./styles";

interface IProps extends Omit<SelectProps, "onChange"> {
  mode?: "dark" | "light";
  label?: string;
  options?: ISelection[];
  onChange?: (v: string | number) => void;
  helperText?: string;
}

const AppSelect: React.FC<IProps> = ({
  mode = "dark",
  options = [],
  onChange,
  error,
  helperText,
  label,
  ...props
}) => {
  return (
    <AppSelectWrapper>
      <FormControl error={error} variant="filled">
        <InputLabel error={error}>{label}</InputLabel>
        <Select
          MenuProps={{
            sx: {
              ".MuiPaper-root": {
                backgroundColor: "#253041",
                marginTop: "4px",
                color: "#fff",
                maxHeight: 250,
              },
            },
          }}
          onChange={(event) => {
            if (
              onChange &&
              (typeof event.target.value === "string" ||
                typeof event.target.value === "number")
            ) {
              onChange(event.target.value);
            }
          }}
          {...props}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </AppSelectWrapper>
  );
};

export default AppSelect;
