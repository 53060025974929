import styled from "@emotion/styled";

export const NewPatientWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  background: linear-gradient(
    0.07deg,
    rgba(3, 6, 13, 1) 35.24%,
    rgba(38, 55, 82, 1) 290.72%
  );
  border: 1px solid #51a7ca;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
  padding: 32px 0 1rem;
  color: #fff;
  overflow-x: hidden;
  text-align: center;
  position: relative;
  width: 100%;
  .close-button {
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    border: none;
    max-width: 34px;
    margin: 0;
    top: 15px;
    right: 10px;
  }
  form {
    margin: 0 auto;
    max-width: 657px;
  }
  .type-checkbox {
    input {
      visibility: hidden;
      display: none;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: lowercase;
      cursor: pointer;
      width: 236px;
      height: 206px;
      background: #253041;
      border: 1px solid #4f5f77;
      border-radius: 20px;
      font-weight: 600;
      font-size: 32px;
      line-height: 34px;
      color: #ffffff;
    }
    input:checked + div {
      border: 2px solid #51a7ca;
    }
  }
  @media screen and (max-width: 600px) {
    .type-checkbox {
      div {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        font-size: 22px;
        line-height: 22px;
      }
    }
  }
`;
