import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretDown } from "react-icons/ai";
import { Button, Fade, Menu, MenuItem } from "@mui/material";

import { LANGUAGES } from "../../helpers/constants";

import { LangSwitcherWrapper } from "./styles";

interface IProps {
  mode?: "dark" | "light";
}

const LangSwitcher: React.FC<IProps> = ({ mode = "dark" }) => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChooseLang = async (code: string) => {
    await i18n.changeLanguage(code);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <LangSwitcherWrapper mode={mode}>
      <Button
        aria-controls={open ? "fade-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        endIcon={
          <AiFillCaretDown
            color="#D9D9D9"
            fontSize={10}
            height={10}
            width={10}
          />
        }
        id="fade-button"
        onClick={handleClick}
      >
        {LANGUAGES.find((lang) => i18n.language.indexOf(lang.code) > -1)?.title}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        open={open}
        PaperProps={{
          sx: {
            "& .MuiMenuItem-root:hover": {
              backgroundColor: mode === "dark" ? "grey.200" : "#253041",
            },
            backgroundColor: mode === "light" ? "rgba(20, 29, 44, 1)" : "#fff",
            color: mode === "light" ? "#fff" : "#000",
          },
        }}
        TransitionComponent={Fade}
        onClose={handleClose}
      >
        {LANGUAGES.map((lang) => (
          <MenuItem key={lang.code} onClick={() => handleChooseLang(lang.code)}>
            {lang.title.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </LangSwitcherWrapper>
  );
};

// "&:before": {
//   content: "",
//   display: "block",
//   position: "absolute",
//   top: 0,
//   right: 14,
//   width: 10,
//   height: 10,
//   left: "50%",
//   bgcolor: "background.paper",
//   transform: "translateY(-50%) rotate(45deg)",
//   zIndex: 0,
// },

export default LangSwitcher;
