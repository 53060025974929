import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container } from "@mui/material";

import { useAppSelector } from "../../../app/hooks";
import logo2Img from "../../../assets/svg/aidentis-2.svg";
import LangSwitcher from "../../../components/LangSwitcher";
import UserMenu from "../../../components/UserMenu";
import PATH from "../../../helpers/PATH";

import { AppHeaderWrapper } from "./styles";

const AppHeader = () => {
  const { t } = useTranslation();
  const { token, user } = useAppSelector((state) => state.userSlice);
  const navigate = useNavigate();

  return (
    <AppHeaderWrapper>
      <Container>
        <div className="header">
          <Link className="logo" to={token ? PATH.APP_CASES : PATH.HOME}>
            <img alt="AIDentis" src={logo2Img} />
          </Link>
          <menu>
            {user?.id ? (
              <li>
                <Button onClick={() => navigate(PATH.APP_TARIFFS)}>
                  {t("common:payment")}
                </Button>
              </li>
            ) : null}
            <li>
              <UserMenu mode="light" />
            </li>
            <li>
              <LangSwitcher mode="light" />
            </li>
          </menu>
        </div>
      </Container>
    </AppHeaderWrapper>
  );
};

export default AppHeader;
