import { API_TAGS } from "../../helpers/constants";
import {
  ICase,
  IGetCasesRequestProps,
  IGetCasesResponseProps,
  IPostCaseCreateRequestProps,
} from "../../types/Case";
import { api } from "../api";

export const caseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCases: builder.query<IGetCasesResponseProps, IGetCasesRequestProps>({
      query: (params) => ({ url: "cases/all", params }),
      providesTags: () => [API_TAGS.CASE],
    }),
    getCase: builder.query<ICase, string | undefined | number>({
      query: (id: string) => ({ url: `cases/${id}` }),
      providesTags: () => [API_TAGS.CASE],
      transformResponse: async (response: ICase): Promise<ICase> => {
        return {
          ...response,
          patientFullName:
            response.patientFirstName + " " + response.patientLastName,
        };
      },
    }),
    postCaseCreate: builder.mutation<
      { id: number },
      IPostCaseCreateRequestProps
    >({
      query: (credentials) => ({
        url: "cases",
        method: "POST",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.CASE],
    }),
    putCaseUpdate: builder.mutation<
      unknown,
      { id: string } & Omit<IPostCaseCreateRequestProps, "caseType">
    >({
      query: ({ id, ...credentials }) => ({
        url: `cases/${id}`,
        method: "PUT",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.CASE],
    }),
    deleteCase: builder.mutation<null, number>({
      query: (id) => ({
        url: `cases/${id}`,
        method: "DELETE",
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.CASE],
    }),
    postCaseComplaint: builder.mutation<null, { id: number; text: string }>({
      query: ({ id, text }) => ({
        url: `cases/${id}/complaint?text=${text}`,
        method: "POST",
      }),
      extraOptions: {},
      invalidatesTags: [],
    }),
    postCaseTreatmentPlan: builder.mutation<null, { id: number; text: string }>(
      {
        query: ({ id, text }) => ({
          url: `cases/${id}/treatment-plan?text=${text}`,
          method: "POST",
        }),
        extraOptions: {},
        invalidatesTags: [],
      }
    ),
  }),
});

export const {
  useGetCasesQuery,
  useGetCaseQuery,
  usePostCaseCreateMutation,
  usePutCaseUpdateMutation,
  useDeleteCaseMutation,
  usePostCaseComplaintMutation,
  usePostCaseTreatmentPlanMutation,
} = caseApi;
