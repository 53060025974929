import styled from "@emotion/styled";

export const LangSwitcherWrapper = styled.div<{ mode: "dark" | "light" }>`
  box-sizing: border-box;
  .MuiButtonBase-root {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${({ mode }) => (mode === "light" ? "#fff" : "#236bcf")};
    svg {
      fill: ${({ mode }) => (mode === "light" ? "#fff" : "#236bcf")};
    }
  }
  .MuiButton-endIcon {
    margin-left: 4px;
    margin-right: 0;
    & > *:first-of-type {
      font-size: 13px;
    }
  }
`;
