import styled from "@emotion/styled";

export type TInputSize = "lg" | "md";

const sizes = {
  lg: {
    padding: "25px 0 8px 12px",
  },
  md: {
    padding: "21px 0 8px 12px",
  },
};

export const InputWrapper = styled.div<{ size: TInputSize }>`
  box-sizing: border-box;
  max-width: 900px;
  display: flex;
  align-items: flex-start;
  .MuiFormLabel-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #fff;
  }
  .MuiInputBase-input {
    padding: ${({ size }) => sizes[size].padding};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #fff;
    background-color: #253041;
    &:disabled {
      color: #fff;
      -webkit-text-fill-color: #fff;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 999950000s ease-in-out 0s,
        color 50000s ease-in-out 0s;
      transition-delay: background-color 999950000s, color 50000s;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .MuiInputLabel-root {
    color: #fff;
  }
  .MuiInputLabel-root.Mui-focused,
  .MuiInputLabel-root.Mui-filled,
  .MuiInputLabel-shrink {
    color: #7c859a;
    font-weight: 400;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: -0.03em;
    transform: translate(12px, 12px) scale(0.75);
  }
  .MuiFormControl-root {
  }
  .MuiInputLabel-root.Mui-error,
  .MuiInputLabel-root.Mui-focused.Mui-error {
    color: #ed5f68;
  }
  .MuiInputBase-root,
  .MuiInputBase-root.Mui-focused,
  .MuiInputBase-root:hover,
  .MuiInputBase-root.Mui-disabled {
    background: #253041;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #fff;
    border-radius: 10px;
    &.Mui-error {
      border-color: #ed5f68;
    }
    &:before,
    &:after {
      display: none;
    }
    .MuiButtonBase-root {
      background: #253041;
    }
  }
  .Mui-error {
    .MuiIconButton-root {
      color: #ed5f68;
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiIconButton-root {
    color: #8f95a1;
  }
  .MuiFormHelperText-root {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 13px;
    margin-bottom: 10px;
    &.Mui-error {
      color: #ed5f68;
    }
  }

  @media screen and (max-width: 600px) {
    .tooltip {
      display: none;
    }
  }
`;

export const TipWrapper = styled.div`
  margin-top: 12px;
  margin-left: 4px;
`;
