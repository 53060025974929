import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";

import { PrivacyLayoutWrapper } from "./styles";

const PrivacyLayout = () => {
  return (
    <PrivacyLayoutWrapper>
      <Container>
        <Outlet />
      </Container>
    </PrivacyLayoutWrapper>
  );
};

export default PrivacyLayout;
