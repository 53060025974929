import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { caseApi } from "../case/api";

const initialState: {
  phoneConfirmation: {
    isOpen: boolean;
  };
  deletePatientConfirmation: {
    isOpen: boolean;
    caseId: null | number;
  };
  newPatient: {
    isOpen: boolean;
  };
  imageCropper: {
    isOpen: boolean;
    image: string;
  };
} = {
  phoneConfirmation: {
    isOpen: false,
  },
  deletePatientConfirmation: {
    isOpen: false,
    caseId: null,
  },
  newPatient: {
    isOpen: false,
  },
  imageCropper: {
    isOpen: false,
    image: "",
  },
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    togglePhoneConfirmationModal: (state, action: PayloadAction<boolean>) => {
      state.phoneConfirmation.isOpen = action.payload;
    },
    changeDeletePatientConfirmationModal: (
      state,
      action: PayloadAction<{ isOpen?: boolean; caseId?: number | null }>
    ) => {
      state.deletePatientConfirmation = {
        ...state.deletePatientConfirmation,
        ...action.payload,
      };
    },
    changeNewPatientModal: (
      state,
      action: PayloadAction<{ isOpen?: boolean }>
    ) => {
      state.newPatient = {
        ...state.newPatient,
        ...action.payload,
      };
    },
    changeImageCropperModal: (
      state,
      action: PayloadAction<{
        isOpen?: boolean;
        image?: string;
      }>
    ) => {
      state.imageCropper = {
        ...state.imageCropper,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      caseApi.endpoints.deleteCase.matchFulfilled,
      (state, action) => {
        state.deletePatientConfirmation = { caseId: null, isOpen: false };
      }
    );
  },
});

export const {
  togglePhoneConfirmationModal,
  changeDeletePatientConfirmationModal,
  changeNewPatientModal,
  changeImageCropperModal,
} = modalSlice.actions;
export default modalSlice.reducer;
