import React, { useState } from "react";
import ReactPhoneInput, { PhoneInputProps } from "react-phone-input-2";

import { PhoneInputWrapper } from "./styles";

import "react-phone-input-2/lib/style.css";

interface IProps extends Partial<PhoneInputProps> {
  error?: boolean;
}

const PhoneInput: React.FC<IProps> = (props) => {
  const [value, setValue] = useState<string | undefined>("");

  return (
    <PhoneInputWrapper>
      <ReactPhoneInput
        areaCodes={{ kz: ["7", "6"] }}
        country="kz"
        disableDropdown
        {...props}
      />
    </PhoneInputWrapper>
  );
};

export default PhoneInput;
