import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { API_TAGS } from "../../helpers/constants";

const initialState: {
  example?: boolean;
} = {};

const cbctSlice = createSlice({
  name: API_TAGS.CBCT,
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const {} = cbctSlice.actions;
export default cbctSlice.reducer;
