import React, { useLayoutEffect, useRef, useState } from "react";
import { Router } from "react-router-dom";
import { BrowserHistory, createBrowserHistory } from "history";

// Can be used to manage navigation state outside of React components
// ex : Redux, Axios interceptors, ...
export const customHistory = createBrowserHistory();

export function CustomBrowserRouter({
  basename,
  children,
}: {
  basename?: string;
  children: React.ReactNode;
}) {
  const historyRef = useRef<BrowserHistory | null>(null);
  if (historyRef.current == null) {
    historyRef.current = customHistory;
  }
  const history = historyRef.current;
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
}
