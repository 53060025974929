import React from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import {
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useMediaQuery,
} from "@mui/material";

import theme from "../../theme";

import { QuestionTooltipWrapper } from "./styles";

interface IProps extends Omit<TooltipProps, "children"> {
  children?: React.ReactNode;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme: localTheme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#253041",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#8599B8",
    backgroundColor: "#253041",
  },
}));

const QuestionTooltip: React.FC<IProps> = ({ placement, ...otherPros }) => {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <QuestionTooltipWrapper>
      <CustomTooltip
        enterTouchDelay={0}
        placement={matches ? "bottom-end" : placement}
        arrow
        disableFocusListener
        {...otherPros}
      >
        <IconButton size="small" tabIndex={-1}>
          <BsFillQuestionCircleFill />
        </IconButton>
      </CustomTooltip>
    </QuestionTooltipWrapper>
  );
};

export default QuestionTooltip;
